import React from 'react'
import { Container } from '../common/AltCard'
import { impact } from '../../utils/data/data'

const Impact = () => {
  return (
    <section className="py-20 lg:py-32 bg-gradient-to-b from-white via-rgba(231, 255, 220, 0.8) to-rgba(239, 249, 202, 0.5) to-transparent">
    <Container>
      <h3  className='text-primary font-extrabold text-center text-5xl  lg:text-left lg:text-6xl'>Our Impact.</h3>
      <div className='grid lg:grid-cols-2 lg:grid-rows-3 gap-5 mt-10'>
        {impact.map((el, i) => (
          <div className='flex items-center gap-3 bg-white p-3 rounded-lg shadow-sm border border-primary h-full'>
            <img src={el.imgUrl} alt="impact" height={76} width={85} />
            <p className='text-sm lg:text-base'>{el.text}</p>
          </div >
        ))}
      </div>
    </Container>  
    </section>
  )
}

export default Impact