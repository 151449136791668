import React, { useState } from "react";
import { Link } from "react-scroll";
import { PartnershipAnimation } from "./PartnerAnimation";
import Button from "../common/Button";

export const HeroLeft = () => {
  const [primary, setPrimary] = useState(true);
  const [white, setWhite] = useState(true);

  return (
    <div>
      <PartnershipAnimation />
      <div>
        <p className="text-lg md:text-2xl pt-7 pb-7">
          Let’s move towards achieving waste scarcity together.
        </p>
        <Link to="signup" smooth={true}>

          <button className="rounded-[59.65px] px-[46.5px] py-[11px] outline-none cursor-pointer text-[16px]
        transition-all bg-[#008300] font-[700] text-[#fff] hover:bg-[#145C53]">
            Join Now
          </button>
          {/* <Button
            content="Join now"
            width={true}
            primary={primary}
            white={white}
          /> */}
        </Link>
      </div>
    </div>
  );
};
