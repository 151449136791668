import React from "react";
import { TypeAnimation } from "react-type-animation";

export const PartnershipAnimation = () => {
  return (
    <>
      <TypeAnimation
        sequence={[
          "Earn Money",
          2000,
          "Be Your Own Boss",
          3000, // Waits 2s
          "Ensure waste scarcity",
          4000,
          () => {
            console.log(" typing!");
          },
        ]}
        wrapper="span"
        cursor={true}
        repeat={Infinity}
        className=" text-3xl md:text-5xl mt-5 mb-10 bg-yellow-200 font-bold p-1"
      />
      <div className="mt-5 mb-5"></div>
      <span className="text-3xl md:text-4xl font-bold mt-10">
        with <span className="text-primary">Pakam</span>
      </span>
    </>
  );
};
