import React, { useState } from "react";
import Testimonial from "../../components/review/Testimonial";
import ExpertReview from "../../components/review/ExpertReview";
import {PageBanner} from "../../components/common/PageBanner";
import { Container } from "../../components/common/AltCard";


const ReviewPage = () => {
  const [tab, setTab] = useState('testimonial')
  return (
    <div className="pt-16">
        <PageBanner text={'Delivering memorable experiences to our customers is our top most priority'}>
          <button onClick={()=>setTab('testimonial')} className={`px-[0.25rem] ${tab==='testimonial'? 'border-b border-[4px] text-white':'text-white opacity-50'}`}>Testimonial</button>
          <button onClick={()=>setTab('expert')} className={`px-[0.25rem] ${tab==='expert'? 'border-b border-[4px] text-white':'text-white opacity-50'}`}>Expert Review</button>
      </PageBanner> 
      <div>
        {
          tab === 'testimonial'?<Testimonial/>: <ExpertReview/>
        }
      </div>
      
    </div>
  );
};

export default ReviewPage;
