import React, { useState } from 'react'
import PrivacyCollector from '../../components/privacy/PrivacyCollector'
import {PageBanner} from '../../components/common/PageBanner'
import { Container } from '../../components/common/AltCard'
import PrivacyGenerator from '../../components/privacy/PrivacyGenerator'

const Privacy = () => {
  const [tab, setTab] = useState('generator')
  return (
    <div className='pt-16'>
    <PageBanner text={'Pakam Mobile Application End User Lincense Aggrement'}>
    <button className={`px-[0.25rem] ${tab==='generator'? 'border-b border-[2px] text-white':'text-white opacity-50'}`} onClick={()=>setTab('generator')}>For Generators</button>
        <button className={`px-[0.25rem] ${tab==='collectors'? 'border-b border-[2px] text-white':'text-white opacity-50'}`}  onClick={()=>setTab('collectors')}>For Collectors</button>
    </PageBanner> 

    <Container>
        {tab === 'generator'?<PrivacyGenerator/>:<PrivacyCollector/>}        
    </Container>
    
</div>
  )
}

export default Privacy