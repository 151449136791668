import React from 'react'

interface BlogProps{
    img: string;
    title: string;
    date: string;
    url: string
}

const BlogCard = ({img, title, date, url}: BlogProps) => {
  return (
    <div style={{backgroundColor:'rgba(255, 255, 255, 1)'}} className='w-full h-[450px] relative shadow-sm'>
      <div className=' h-[50%] md:h-[60%]'>
        <img src={img} alt="" className='w-full h-full' />
      </div>
      <div className='p-6'>
        <div>
            <a href={url} style={{color:'rgba(0, 87, 0, 1)'}} target='_blank' className='underline font-[800]'>{title}</a>
        </div>
        <div className='flex justify-between w-full absolute left-0 bottom-3 px-6'>
            <div className='flex space-x-1 items-center '>
                <img src="svg/Discovery.svg" alt="discovery" className='w-[15px] h-[15px]' />
                <span className=' text-[12px] md:text-[14px]'>{date}</span>
            </div>
            <div>
                <p style={{backgroundColor:'rgba(245, 245, 245, 1)', color:'rgba(0, 0, 0, 1)'}} className='rounded-2xl px-6 py-1 text-center font-[Raleway] text-[12px] md:text-[14px]'>Article</p>
            </div>
        </div>
      </div>
    </div>
  )
}

export default BlogCard
