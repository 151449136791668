import React from 'react'
import { recycledata } from '../../utils/data/data'
import { Container } from '../common/AltCard'

const RecycleEarn = () => {
  return (
    <Container>
        <div data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-in-out"
     data-aos-duration="1500" className='py-[4rem]'>
            <div>
                <div className='hidden md:flex w-full justify-center items-center flex-col'>
                    <h1 className=' text-center text-[#35393F] font-Rob font-[800] text-[48px] leading-[45px]' ><span className='border-[4px] border-[#F3A218]'>Recycle.</span> Earn. Impact</h1>
                     <p className='text-center font-int font-[500] text-[16px] text-[#7A828F] my-8 lg:w-[38%]'>With Pakam, your recycling actions have a meaningful impact. Earn rewards for every item recycled.</p>
                </div>

                <div className='md:hidden'>
                    <h1 className=' text-center text-[#35393F] font-Rob font-[800] text-[28px] md:text-[48px] leading-[45px]' ><span className='border-[3px] border-[#F3A218]'>Earn</span> Rewards with Purpose</h1>
                
                    <p className='text-center font-int font-[500] text-[16px] text-[#7A828F] my-4 md:my-8'>Use your earnings to purchase health insurance at an affordable price, donate to registered charities, or payout to your local bank account, all within the app.</p>                    
                </div>
                

            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
                {
                    recycledata.map((i, id)=>{
                        return(
                            <div className='border p-3 rounded-xl space-y-3 hover:shadow-lg transition-all ease-in-out duration-500'>
                                <img className='' src={i.icon} alt="icon" />
                                <h2 className='font-[500] text-[#131313] font-int text-[14px]'>{i.title}</h2>
                                <p className='text-[#606060] font-[400]  font-int text-[14px]'>{i.desc}</p>
                            </div>
                        )
                    })
                }

            </div>
        </div>        
    </Container>

  )
}

export default RecycleEarn

