import React from 'react'

interface PageProps{
  text:string
  children:React.ReactNode
  para?:string
  isUpdate?:boolean
}

export const PageBanner:React.FC<PageProps> = ({text, children, para, isUpdate}) => {
  return (
    <div className='relative'>
        <div style={{padding: '134px initial'}} className=' flex flex-col items-center justify-center h-[290px] min-h-[200px] sm:h-[330px] lg:h-[330px] space-y-4  lg:space-y-5 -mt-5 bg-header-bg bg-no-repeat w-full relative bg-center bg-cover items-end '>
            {isUpdate &&<p className='font-[400] md:text-lg font-[Raleway] text-white text-center' >{para}</p>}
            
            <h1 className='text-white text-center font-extrabold text-3xl md:text-4xl lg:text-5xl w-[95%] sm:w-[80%]'>{text}</h1>

            <div className='flex space-x-10 lg:space-x-16 absolute bottom-5 sm:bottom-2 lg:bottom-10 z-[999] text-sm'>
              {children}
            </div>
        </div>

    </div>
  )
}
export const CareerPageBanner:React.FC<PageProps> = ({text, children, para, isUpdate}) => {
  return (
    <div className='relative'>
        <div style={{padding: '134px initial'}} className=' flex flex-col items-center justify-center h-[290px] min-h-[200px] sm:h-[330px] lg:h-[330px] space-y-4  lg:space-y-5 -mt-5 bg-header-bg bg-no-repeat w-full relative bg-center bg-cover items-end '>
            
            
            <h1 className='text-white text-center font-extrabold text-3xl md:text-4xl lg:text-5xl w-[95%] sm:w-[80%]'>{text}</h1>

            <p className='font-[400] md:text-lg font-[Raleway] text-white text-center' >{para}</p>

            <div className='flex space-x-10 lg:space-x-16 absolute bottom-5 sm:bottom-2 lg:bottom-10 z-[999] text-sm'>
              {children}
            </div>
        </div>

    </div>
  )
}

