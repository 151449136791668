import React from 'react'
import NewsCard from './NewCard'
import { InterviewData, NewsMediaArticles, PressRelase } from '../../utils/data/data'


export const NewSection = () => {
  return (
    <div style={{backgroundColor:'rgba(248, 255, 244, 1)'}} className='w-full py-12 px-6 lg:px-10 xl:px-12 grid grid-col-1 sm:grid-cols-2 xl:grid-cols-3 gap-6'>
        {
            NewsMediaArticles.map((news, index)=>
                <NewsCard key={index} dates={news.dates} info={news.info} subtext={news.subtext} img={news.imgSrc} title={news.headline} url={news.link} date={news.dates[0].text} />
            )
        }
    </div>
  )
}
export const PressRelease = () => {
  return (
    <div style={{backgroundColor:'rgba(248, 255, 244, 1)'}} className='w-full py-12 px-6 lg:px-10 xl:px-12 grid grid-col-1 sm:grid-cols-2 xl:grid-cols-3 gap-6'>
        {
            PressRelase?.map((news, index)=>
                <NewsCard key={index} dates={news.dates} info={news.info} subtext={news.subtext} img={news.imgSrc} title={news.headline} url={news.link} date={news.dates[0].text} />
            )
        }
    </div>
  )
}
export const Interview = () => {
  return (
    <div style={{backgroundColor:'rgba(248, 255, 244, 1)'}} className='w-full py-12 px-6 lg:px-10 xl:px-12 grid grid-col-1 sm:grid-cols-2 xl:grid-cols-3 gap-6'>
        {
            InterviewData?.map((news, index)=>
                <NewsCard key={index} dates={news.dates} info={news.info} subtext={news.subtext} img={news.imgSrc} title={news.headline} url={news.link} date={news.dates[0].text} />
            )
        }
    </div>
  )
}

