import React, { useState } from 'react'
import TermsCollectors from '../../components/terms/TermsCollectors'
import {PageBanner} from '../../components/common/PageBanner'
import TermsGenerator from '../../components/terms/TermsGenerator'
import { Container } from '../../components/common/AltCard'

const TermsAndCondition = () => {
    const [tab, setTab] = useState('generator')
  return (
    <div className='pt-16'>
    <PageBanner text={'Terms and Conditions'}>
        <button className={`px-[0.25rem] ${tab==='generator'? 'border-b border-[2px] text-white':'text-white opacity-50'}`} onClick={()=>setTab('generator')}>For Generators</button>
        <button className={`px-[0.25rem] ${tab==='collectors'? 'border-b border-[2px] text-white':'text-white opacity-50'}`}  onClick={()=>setTab('collectors')}>For Collectors</button>
    </PageBanner>
    <Container>
        {tab === 'generator'?<TermsGenerator/>:<TermsCollectors/>}        
    </Container>
    </div>
  )
}

export default TermsAndCondition