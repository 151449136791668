import React from "react";

import { PerksBenefitsModel } from "../../../utils/data/data";

const PerkCard = ({ imgAlt, imgUrl, text }: PerksBenefitsModel) => {
  return (
    <div style={{ boxShadow: '0px 9.34521px 27.4405px rgba(215, 228, 249, 0.3)'}} className="bg-white h-[170px] md:h-[228px] text-center flex flex-col justify-center items-center gap-2  ease-in-out duration-500 hover:cursor-pointer rounded-lg translate-y-[-5px]">
      <img src={imgUrl} alt={imgAlt} height={45} width={50}/>
      <p className="text-body text-xs md:text-base font-medium tracking-wide">{text}</p>
    </div>
  );
};

export default PerkCard;
