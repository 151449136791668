import React from 'react'

interface NewsProps{
    img: string;
    title: string;
    subtext: string;
    date: string;
    url: string
    info: { imgSrc: any; text: string }[];
    dates: { imgSrc: any; text: string }[];
}

const NewsCard = ({img, title, subtext, dates, url, info}: NewsProps) => {
  return (
    <div style={{backgroundColor:'rgba(255, 255, 255, 1)'}} className='w-full h-[450px] md:h-[360px] lg:h-[450px] relative shadow-sm p-6'>
      <div className=' h-[50%] md:h-[60%]'>
        <img src={img} alt="" className='w-full h-full' />
      </div>
      <div className='pt-4'>
        <div>
            <a href={url} style={{color:'rgba(0, 87, 0, 1)'}} target='_blank' className='underline font-[800]'>{title}</a>

            <p className='text-xs  tracking-wider pt-2 pb-6 leading-5 font-medium'>{`${subtext.substring(0, 150)}....`}</p>
        </div>
        <div className='flex justify-between w-full absolute left-0 bottom-3 px-6'>
            {info?.map(({ text, imgSrc }, index) => (
                <div className="flex gap-1">
                  <img src={imgSrc} alt="Pakam" width={15} height={15}/>
                  <small>{text}</small>
                </div>
              ))}
              {dates?.map(({ imgSrc, text }, index) => (
                <div key={index} className="flex gap-1 items-center">
                  <img src={imgSrc} alt="Pakam" width={10} height={10}/>
                  <small>{text}</small>
                </div>
              ))}
        </div>
      </div>
    </div>
  )
}

export default NewsCard
