import React from 'react'
import Banner from '../../components/common/Banner'
import BlogSection from '../../components/blog'

const Blog = () => {
  return (
    <div className='pt-16'>
      <Banner para='Latest Updates' title='Pakam Blog'/>
      <BlogSection/>
    </div>
  )

  }
export default Blog
