import React from 'react'

const TermsCollectors = () => {
  return (
    <div className='pt-10 pl-10px pr-0'>
    <div>
      <p>
        These General Terms set forth the main terms and conditions applying
        to and governing the usage of the Pakam Services. In order to provide
        collection Services via using the Pakam Platform you must agree to the
        terms and conditions that are set forth below.
      </p>
    </div>
    <div>
      <h4>1. DEFINITIONS</h4>
      <p>
        1.1. The term “us” or “we” refers to Pakam Operations. Pakam is a
        private limited company incorporated and registered under the laws of
        federal republic of Nigeria with registration No. 1796077, registered
        office 127 Ogunlana Drive, Surulere Lagos, Nigeria, or other Pakam
        group company or cooperation partner where Pakam Services are not
        provided by Pakam Operations. In order to use Pakam app you must agree
        to the terms and conditions that are set out below:
      </p>
      <p>
        1.2. Pakam Services – services that Pakam provides, including
        provision and maintenance of Pakam App, Pakam insight Platform, In-app
        Payment, customer support, communication between the collector and the
        generator and other similar services.
      </p>
      <p>
        1.3. Pakam App – a smartphone application for collector and generator
        to Schedule and accept Collection Services
      </p>
      <p>
        1.4. Pakam Platform – technology connecting Generators with Collectors
        to help them dispose their waste more efficiently smartly and
        on-demand.
      </p>
      <p>
        1.5. Generator – a person scheduling Collection Services by using
        Pakam Platform.
      </p>
      <p>
        1.6. Collector (also referred to as „you “) – the person providing
        Collection Services via the Pakam Platform. Each Collector will get a
        personal Pakam Collector Account to use Pakam App and Pakam insight
        Platform.
      </p>
      <p>
        1.7. Agreement – this agreement between Collector and Pakam regarding
        the use of Pakam Services which consists of:
        <span>1.7.1. these General Terms; </span>
        <span>
          1.7.2. special terms displayed in Pakam App, e.g regarding price
          info or service descriptions;
        </span>
        <span>1.7.3. the Collector’s guidelines; and </span>
        <span>
          1.7.4. other terms referred to in this Agreement as may be amended
          from time to time.
        </span>
      </p>
      <p>
        1.8. Price – the price a Generator is obliged to pay Collector for
        provision of the Collection Services and vice versa.
      </p>
      <p>
        1.9. Pakam Fee – the fee that Collector is obliged to pay to Pakam for
        using the Pakam Platform.{" "}
      </p>
      <p>
        1.10. In-app Payment – bank transfer, cards, and other payment methods
        used by the Generator/Collector via the Pakam App to pay for the Pakam
        Services.
      </p>
      <p>
        1.11. Pakam Collector’s Account – access to a website containing
        business insight, information, and documents regarding usage of the
        Pakam Services in course of provision of Collection Services,
        including accounting documentation. Collectors may access the Pakam
        Collector Account at{" "}
        <a href="https://dashboard.pakam.ng" target="_blank">
          https://dashboard.pakam.ng
        </a>{" "}
        by entering username and password.
      </p>
      <p>
        1.12 Collection Services – Collection service a Collector is providing
        to Generator whose schedule collector has accepted through the Pakam
        App.
      </p>
    </div>
    <div>
      <h4>2. ENTRY INTO THE AGREEMENT</h4>
      <p>
        2.1. Prior to using the Pakam Services, you must sign up by providing
        the requested information in the signup application on website and
        uploading necessary documentation as required by us. You may sign up
        either as a legal or a natural person. Upon successful completion of
        the signup application, we will provide you with a personal account
        accessible via a username and password. By clicking the „Sign up"
        button located at the end of the signup application, you represent and
        warrant that:
      </p>{" "}
      <p>
        2.1.1. pursuant to valid legal acts, you are entitled to enter into an
        agreement with us to use the Pakam Platform for providing the
        Collection Service;
      </p>
      <p>
        2.1.2. you have carefully studied, fully understand and agree to be
        bound by these General Terms, including all obligations that arise as
        provided herein and from Agreement;
      </p>{" "}
      <p>
        2.1.3. all the information you have presented to us is accurate,
        correct and complete;
      </p>{" "}
      <p>
        2.1.4. you will keep Pakam Collector Account accurate and profile
        information updated at all times;
      </p>{" "}
      <p>
        2.1.5. you will not authorize other persons to use your Pakam
        Collector’s Account nor transfer or assign it to any other person;
      </p>{" "}
      <p>
        2.1.6. you will not use the Collector’s Services for unauthorized or
        unlawful purposes and impair the proper operation of the Pakam
        Services;
      </p>{" "}
      <p>
        {" "}
        2.1.7. at all times, you fully comply with all laws and regulations
        applicable in the state you are providing Collector Services in,
        including (but not limited to) laws regulating waste management and
        transport services;
      </p>{" "}
      <p>
        {" "}
        2.2. In the case of a waste hauler, you are obliged to provide your
        bank requisites in course of filling the payment details upon
        registration. In case you are a legal person, you must insert the bank
        account of the company. We are transferring In-app Payment fees to the
        bank account that you have provided. We are not liable for any
        incorrect money transactions in case you have provided wrong bank
        requisites. We advise that Haulers and collectors use Pakam Financial
        institution partner{" "}
        <a href="https://sterling.ng" target="_blank">
          https://sterling.ng
        </a>{" "}
        for easy and fast financial transaction.
      </p>
      <p>
        2.4. You agree that in specific areas, cities or countries Pakam
        Operations may assign any of our obligations arising from the General
        Terms or Agreement to Pakam partners. This includes, among else,
        assigning the rights and obligations regarding reviewing documents
        related to signup applications, trainings, collection of Pakam Fees,
        forwarding you the fees due, mediating In-app Payment, licensing the
        Pakam App,
      </p>{" "}
      <p>
        2.5. Registering the account as a legal person (i.e. a company). You
        are considered to be a legal person, if the recipient of the fees is
        marked as a legal person in payment details (as accessible in the
        Pakam Collectors Account). In such case, the indicated legal person is
        considered to be the provider of Collection Services and a party to
        these General Terms, Agreement and any further agreements. Only the
        specific natural person indicated in the signup process may factually
        provide the collection Services. Such natural person may use the
        account of the collector only if he/she has read and agrees to be
        bound by these General Terms and any further documentation that is
        part of the Agreement. THE LEGAL PERSON IN THE PAYMENT DETAILS AND THE
        NATURAL PERSON FACTUALLY PROVIDING THE COLLECTION SERVICES UNDER PAKAM
        ACCOUNT SHALL REMAIN JOINTLY AND SEVERALLY LIABLE FOR ANY INFRINGEMENT
        OF THE GENERAL TERMS AND AGREEMENT CONDUCTED BY THE COLLECTOR.
      </p>{" "}
    </div>
    <div>
      <h4>3. RIGHT TO USE Pakam APP AND Pakam Collectors ACCOUNT</h4>
      <p>
        3.1. License to use the Pakam App and the Pakam Collectors Account.
        Subject to your compliance with the Agreement, We hereby grant you
        have a license to use the Pakam App and the Pakam Collectors Account.
        The license does not grant you the right to sublicense or transfer any
        rights to the third persons.
      </p>
      <p>
        {" "}
        3.2. In course of using the Pakam App and/or Bolt Pakam collectors
        Account you may not: 3.2.1. decompile, reverse engineer, or otherwise
        attempt to obtain the source code of the Pakam App, the Pakam
        collectors Account or other software of Pakam;
        <span>
          {" "}
          3.2.2. modify the Pakam App or the Pakam Collectors Account in any
          manner or form or to use modified versions of the Pakam App or Pakam
          collectors Account;{" "}
        </span>
        <span>
          {" "}
          3.2.3. transmit files that contain viruses, corrupted files, or any
          other programs that may damage or adversely affect the operations on
          Pakam Platform;
        </span>
        <span>
          {" "}
          3.2.4. attempt to gain unauthorized access to the Pakam App, Pakam
          Collectors Account or any other Pakam Services.
        </span>
        <span></span>
      </p>{" "}
      <p>
        3.3. The License granted herein revokes automatically and
        simultaneously with termination of the Agreement. After termination of
        the Agreement you must immediately stop using the Pakam App and the
        Pakam Collectors Account and we are entitled to block and delete
        collectors account without a prior notice.{" "}
      </p>
      <p>
        3.3. The License granted herein revokes automatically and
        simultaneously with termination of the Agreement. After termination of
        the Agreement you must immediately stop using the Pakam App and the
        Pakam Collectors Account and we are entitled to block and delete
        collectors account without a prior notice.{" "}
      </p>
      <p>
        3.4. Using tags and labels of Pakam. Additionally, we may give you
        tags, labels, stickers or other signs that refer to Pakam brand or
        otherwise indicate you are using the Pakam Platform. We grant you a
        non-exclusive, non-sublicensable, non-transferable license to use such
        signs and only for the purpose of indicating you are providing
        Collection Services via the Pakam Platform. After termination of the
        Agreement you must immediately remove and discard any signs that refer
        to Pakam brand.{" "}
      </p>
      <p>
        3.5. All copyrights and trademarks, including source code, databases,
        logos and visual designs are owned by Pakam and protected by
        copyright, trademark and/or trade secret laws and international treaty
        provisions. By using the Pakam Platform or any other Pakam Services
        you do not acquire any rights of ownership to any intellectual
        property.
      </p>
    </div>{" "}
    <div>
      <h4> 4. PROVIDING THE COLLECTION SERVICES</h4>
      <p>
        4.1. The Collector’s Obligations. You hereby guarantee to provide
        Transportation Services in accordance with the General Terms,
        Agreement as well as laws and regulations applicable in the state
        where you are providing Collection Services. Please note that you are
        fully liable for any violation of any local laws and regulations as
        may arise from providing Collection Services.
      </p>
      <p>
        4.2. You must have all licenses (including a valid driver’s license),
        regulatory permits, car insurance, liability insurance (if
        applicable), registrations, certifications and other documentation
        that are required in the applicable jurisdiction for providing the
        Collection Services. It is your obligation to maintain the validity of
        all aforementioned documentation. Pakam reserves the right to require
        you to present evidence and submit for review all the necessary
        licenses, permits, approvals, authority, registrations, and
        certifications.
      </p>
      <p>
        4.3. You must provide the Collection Services in a professional manner
        in accordance with the business ethics applicable to providing such
        services and endeavour to perform the generators's schedule in the
        best interest of the Generator.
      </p>
      <p>
        4.4. You retain the sole right to determine when you are providing the
        Collection Services. You shall accept, decline or ignore Collection
        Services requests made by Generator at your own choosing within the
        collection window.
      </p>
      <p>
        4.5. Costs you incur while providing the Collection Services. You are
        obliged to provide and maintain all equipment and means that are
        necessary to perform the Collection Services at your own expense,
        including a Truck/bus/tricycle/cart, smartphone, etc. You are also
        responsible for paying all costs you incur in the course of performing
        the Collection Services including, but not limited to, fuel, mobile
        data plan costs, duty fees, amortization of the vehicle, insurance,
        relevant corporate or payroll taxes etc. Please bear in mind that
        using the Pakam App may bring about consummation of large amount of
        data on your mobile data plan. Thus, we suggest you to subscribe for a
        data plan with unlimited or very high data usage capacity.
      </p>
      <p>
        4.6. Payment. All payment and transaction to collection or generator
        will be via Pakam wallet account system. You are not to charge or pay
        generator by cash. Payment for collection is fixed by collector in
        agreement with the relevant authority.
      </p>
    </div>
    <div>
      <h4>5. PAKAM FEES</h4>{" "}
      <p>
        5.1. In order to use the Pakam Services, you are obliged to pay to a
        fee (i.e. the Pakam Fee). The Pakam Fee is paid based on the total
        waste collected at 15% of the price set for collection on a weekly
        basis into a central account which is provided by Pakam. The amount of
        the Pakam Fee is made available to you via e-mail, Pakam App, Pakam
        Collectors Account or other pertinent means. Please acknowledge that
        the Pakam Fee may change from time to time. We shall send you a prior
        notification of each such change. Fees will be inclusive of applicable
        taxes where required by law. Fees paid by you are final and
        non-refundable, unless otherwise determined by Pakam.
      </p>{" "}
      <p>
        5.2. You must pay the Pakam Fee and any other fees due to us for the
        previous week at latest by the 2nd day of the following week. Upon
        delay with payment of the Pakam Fee, you shall be obliged to pay a
        penalty of late payment in the amount of 0,05% (zero point zero five
        percent) of the unpaid amount per day. You are obliged to cover all
        costs incurred by us, which are related to debt collection activities.
      </p>{" "}
    </div>{" "}
    <div>
      <h4>6. IN-APP PAYMENTS</h4>
      <p>
        6.1. We may enable Generator/collector to pay for the Collection
        Service via cards, carrier billing and other payment methods (Pakam
        Business etc) directly in the Pakam App (i.e. In-app Payment). You
        hereby authorise us as your commercial agent to receive the Payment or
        other payment paid by the Collector via In-app Payment and to forward
        relevant funds to you. Any payment obligation made by the
        Generator/collector via the In-app Payment shall be considered
        fulfilled as of the time that the payment has been made.
      </p>{" "}
      <p>
        6.2. You may not refuse payment to/by the Generator via the In-app
        Payment, or influence the Generator against the use of the In-app
        Payment. In case you refuse to accept an In-app Payment without just
        cause, we shall be entitled to charge you a contractual penalty in the
        amount of 2,000 Naira for every refusal and/or block your right to use
        the Pakam Services in case of repetitive behaviour.
      </p>{" "}
      <p>
        6.3. Pakam reserves the right to distribute promo code to collectors
        at our discretion on a per promotional basis.
      </p>{" "}
    </div>{" "}
    <h4>7. CUSTOMER SUPPORT</h4>{" "}
    <p>
      We provide the Collector customer support regarding the use of the Pakam
      Services. We have the right to stop providing the customer support
      services in case you are in delay with any of the payments for more than
      5 (five) calendar days.
    </p>
    <div>
      <h4>8. RATINGS AND ACTIVITY</h4>
      <p>
        8.1. In order to guarantee high-quality service and provide additional
        reassurance to Generator, you hereby acknowledge that the Generators
        may provide you a rating and leave feedback regarding the quality of
        the Collection Services that you have provided. Your average rating
        will be linked to your Collector´s account and will be available to
        Generator’s at Pakam App. If we find out the rating or comment is not
        given in good faith, this rating or comment may not be projected in
        the calculations of your rating.
      </p>
      <p>
        8.2. In addition to the rating, we measure your level of activity and
        provide you with an activity score, which is based on your activity
        regarding accepting, declining, not responding and completing
        collection Service requests.
      </p>{" "}
      <p>
        8.3. In order to provide reliable services to generator, we may
        determine a minimum average rating and a minimum activity score that
        Drivers must establish and maintain. If, after a pertinent
        notification from us, you do not increase your average rating or
        activity score to minimum level within the prescribed time period,
        your Collector´s account will be automatically suspended either
        temporarily or permanently. We may reverse the suspension of your
        account if it is merited by any external circumstances or it is
        detected that the suspension was caused by a system error or false
        ratings.
      </p>{" "}
    </div>
    <div>
      <h4> 9. MARKET OVERVIEWS AND CAMPAIGNS</h4>
      <p>
        9.1. Market overviews. We may send you, via the Pakam App, Pakam
        Collectors Account, SMS, e-mail or other means, market overviews, in
        order to increase your awareness regarding when the demand by the
        Generators is highest. Such market overviews are merely recommendatory
        and do not constitute any obligations for you. As the market overview
        estimations are based on previous statistics, we cannot give any
        guarantees that the actual market situation will correspond to the
        estimations provided in the market overview.
      </p>{" "}
    </div>
    <div>
      <h4>10. RELATIONSHIP BETWEEN YOU, US AND THE GENERATOR </h4>{" "}
      <p>
        10.1. You hereby acknowledge and agree that we provide an information
        society service and do not provide Collection Services. By providing
        the Pakam Platform and Pakam Services, we act as marketplace
        connecting Generator with Collector to enable proper disposal in an
        efficient manner. You acknowledge that you are providing the
        Collection Services on the basis of a contract for carriage/collection
        of generators waste and that you provide the Collection Services
        either independently or via a company as an economic and professional
        activity. Pakam, as the operator of Pakam App acts as the commercial
        agent of the Collector for the mediation of conclusion of contracts
        between the Collector and the Generator, and thus, among other things,
        accepts/ensure payments from/to the Generator and forwards the
        payments to the collector/Generator.
      </p>{" "}
      <p>
        {" "}
        10.2. You acknowledge that no employment agreement nor an employment
        relationship has been or will be established between you and us. You
        also acknowledge that no joint venture or partnership exists between
        you and us. You may not act as an employee, agent or representative of
        us nor bind any contract on behalf of us. If due to the implication of
        mandatory laws or otherwise, you shall be deemed an employee of us,
        you hereby agree to waive any claims against us that may arise as a
        result of such implied employment relationship.
      </p>
      <p>
        10.3. You may not transfer your rights and obligations deriving from
        the General Terms or Agreement to any third party.
      </p>
    </div>
    <div>
      <h4>11. PROCESSING OF PERSONAL DATA, ACCESS TO DATA</h4>{" "}
      <p>
        11.1. Your personal data will be processed in accordance with the
        Privacy Notice, available at{" "}
        <a href="https://pakam.ng/privacy-policy" target="_blank">
          https://pakam.ng/privacy-policy
        </a>
      </p>
      <p>
        11.2. Pakam has access to all personal data and other data provided or
        generated in connection with your use of the Pakam Services. Pakam
        shall take all reasonable steps to ensure confidentiality of such data
        and comply with all applicable Privacy Policies and laws whenever such
        data contains personal data. Except where otherwise provided by
        applicable Privacy Policies and laws, Pakam maintains access to such
        data also after the Agreement between you and Pakam is terminated.
      </p>{" "}
      <p>
        11.3. You have access to personal and other data provided by you or
        generated in connection with your use of the Pakam Services to the
        extent that is made available to you under your Pakam Collectors
        Account through Pakam App. You shall take all reasonable steps to
        ensure confidentiality of such data and comply with applicable Privacy
        Policies and laws as long and to the extent that such data contains
        personal data of Generators.
      </p>{" "}
    </div>
    <div>
      <h4>12. LIABILITY </h4>
      <p>
        12.1. The Pakam Platform is provided on an &quot;as is&quot; and “as
        available” basis. We do not represent, warrant or guarantee that
        access to Pakam Platform will be uninterrupted or error free. As the
        usage of Pakam Platform for requesting collection services depends on
        the behavior of generators, we do not guarantee that your usage of the
        Pakam Platform will result in any Collection Service requests.
      </p>
      <p>
        12.2. To the maximum extent permitted under the applicable law, we,
        nor Pakam’s representatives, directors and employees are not liable
        for any loss or damage that you may incur as a result of using the
        Pakam Services, including but not limited to:
        <span>
          12.2.1. any direct or indirect property damage or monetary loss;
          12.2.2. loss of profit or anticipated savings;
        </span>
        <span>
          12.2.3. loss of business, contracts, contacts, goodwill, reputation
          and any loss that may arise from interruption of the business;
        </span>
        <span>12.2.4. loss or inaccuracy of data; and</span>
        <span>12.2.5. any other type of loss or damage. </span>
      </p>{" "}
      <p>
        12.3. The financial liability of us in connection with violating the
        General Terms or Agreement will be limited to 5000 Naira. You shall
        have the right to claim for damages only if we have deliberately
        violated the General Terms or Agreement
      </p>
      <p>
        12.4. We shall not be liable for the actions or non-actions of the
        Generators and shall not be liable for any loss or damage that may
        incur to you or your asset as a result of actions or non-actions of
        the generator.
      </p>
      <p>
        12.5. You shall be fully liable for breach of the General Terms,
        Agreement or any other applicable laws or regulations and must stop
        and remedy such breach immediately after receipt of a respective
        demand from us or any state authority. You shall indemnify us for any
        direct and/or indirect loss and/or damage, loss of profits, expense,
        penalty, fine that we may occur in connection with your breach of the
        General Terms, Agreement and laws and regulations. If Passenger
        presents any claims against us in connection with your provision of
        Collection Services, then you shall compensate such damage to us in
        full within 7 (seven) days as of your receipt of the respective
        request from us. In case we are entitled to present any claims against
        you, then you shall compensate us any legal costs related to
        evaluation of the damages and submission of claims relating to
        compensation for such damage.
      </p>
    </div>
    <div>
      <h4>13. TERM, SUSPENSION AND TERMINATION </h4>
      <p>
        13.1. The conditions expressly specified in these General Terms shall
        enter into force as of submitting the signup application. Agreements
        and other terms shall enter into force once the specific document or
        message has been made available to you and you commence or continue
        providing Collection Services on Pakam Platform.
      </p>
      <p>
        13.2. You may terminate the Agreement at any time by notifying Pakam
        at least 7 (seven) days in advance, after which your right to use the
        Pakam Platform and Pakam Services shall terminate. Pakam may terminate
        the Agreement at any time and for any reason at the sole discretion of
        us by notifying you at least 3 (three) days in advance.
      </p>
      <p>
        13.3. Pakam is entitled to immediately terminate the Agreement and
        block your access to the Pakam Platform without giving any advance
        notice in case you breach the General Terms or Agreement, any
        applicable laws or regulations, disparage Pakam, or cause harm to
        Pakam’s brand, reputation or business as determined by Pakam in our
        sole discretion. In the aforementioned cases we may, at own our
        discretion, prohibit you from registering a new Collector account.
      </p>
      <p>
        13.4. We may also immediately suspend (block) your access to the Pakam
        Platform and to the Pakam Collectors Pakam Collectors Account for the
        period of investigation, if we suspect an infringement of the
        Agreement or fraudulent activity from your behalf. The block of access
        will be removed once the investigation disproves such suspicions.
      </p>
      <p>
        13.5. We are aiming to provide the highest quality service to all
        Generator therefore we are monitoring the activity of Collectors on
        Pakam Platform. If you fail to meet the minimal service requirements,
        such as the minimal rating and activity score, we are entitled to
        immediately terminate the Agreement without giving any advance notice.
      </p>
    </div>
    <div>
      <h4>14. AMENDMENTS </h4>{" "}
      <p>
        14.1. Pakam reserves the right to amend these General Terms anytime by
        uploading the revised version on its website{" "}
        <a href="https://pakam.ng/terms-and-conditions" target="_blank">
          https://pakam.ng/terms-and-conditions
        </a>{" "}
        and notifying you (e.g. via e-mail, Pakam App or Pakam collectors
        Account) whenever, in the reasonable opinion of Pakam, such amendments
        are material.
      </p>
      <p>
        14.2. Pakam shall provide at least 15 days advance notice (e.g., via
        e-mail, Pakam App or Pakam Collectors Account) about the amendments
        that affect the rights of Business Users Operating in the Member
        State, unless:
        <span>
          14.2.1. Pakam is subject to a legal or regulatory obligation which
          requires it to amend the General Terms in a manner which does not
          allow it to respect the advance notice period.
        </span>
        <span>
          14.2.2. immediate amendment is required to address an unforeseen and
          imminent danger related to health, safety or cybersecurity risks, or
          defending the Pakam Services, Generator or Collector from fraud,
          malware, spam or data breaches;
        </span>
        <span>
          14.2.3. you have elected to waive the advance notice period (e.g.
          you continue to use Pakam Services after receipt of the notice of
          amendment); or 14.2.4. in the reasonable opinion of Pakam,
          amendments are beneficial for the Collector and do not require
          technical adjustments from them.
        </span>
      </p>
      <p>
        14.3. If you do not agree to the amendments of the General Terms or
        other conditions of the Agreement, you have the right to terminate the
        Agreement by discontinuing the use of the Pakam Services and providing
        termination notice to Pakam. The termination of the Agreement takes
        effect on the effective date of the proposed amendment, unless
        otherwise provided in your termination notice. Your use of the Pakam
        Services on or after the effective date of the amendment constitutes
        your consent to be bound by the General Terms or Agreement, as
        amended.
      </p>
    </div>
    <div>
      <h4>15. APPLICABLE LAW AND COURT JURISDICTION </h4>
      <p>
        15.1. The General Terms and Agreement shall be governed by and
        construed and enforced in accordance with the laws of Federal Republic
        of Nigeria. If the respective dispute resulting from General Terms or
        Agreement could not be settled by negotiations, then the dispute shall
        be solved in Federal high Court.
      </p>
    </div>
    <div>
      <h4>16. NOTICES </h4>
      <p>
        16.1. You are obliged to immediately notify us of any changes to your
        contact information.
      </p>
      <p>
        16.2. Any notice required to be given under the General Terms and
        Agreement shall be sufficiently given if:
        <span> 16.2.1. delivered personally, </span>
        <span>16.2.2. sent by courier with proof of delivery, </span>
        <span>16.2.3. sent by registered mail, ,</span>
        <span>16.2.4. sent by e-mail or </span>
        <span>
          16.2.5. made available via the Pakam App or Pakam Collectors
          Account.
        </span>
      </p>
      <p>
        16.3 Any notice which is sent or dispatched in accordance with the
        previous clause shall be deemed to have been received:
        <span>
          16.3.1. if delivered personally, at the time of delivery to the
          party;
        </span>
        <span>
          16.3.2. if delivered by courier, on the date stated by the courier
          as being the date on which the envelope containing the notice was
          delivered to the party;
        </span>
        <span>
          16.3.3. if sent by registered mail, on the 10th day after handing
          the document over to the post office for delivery to the party;
        </span>
        <span>
          16.3.4. if made available via the Pakam App or Pakam Collectors
          Account, or
        </span>
        <span>
          16.3.5. if sent by e-mail, on the day the party receiving the e-mail
          confirms receiving the respective e-mail or on the 2nd day following
          the dispatch of the e-mail provided that the sender has not received
          an error notice (notifying that the e-mail was not delivered to the
          party) and has sent the e-mail again on the next calendar day and
          has not received a similar error notice.
        </span>
      </p>
    </div>
    <div>
      <h4>17. FINAL PROVISIONS </h4>
      <p>
        If any provision of the General Terms is held to be unenforceable, the
        parties shall substitute for the affected provision an enforceable
        provision that approximates the intent and economic effect of the
        affected provision.
      </p>
    </div>
    <p className="text-base font-bold pt-4 ">
      Date of entry into force of the General Terms: 10.09.2022.
    </p>
    {/* test */}
    <br />
  </div>
  )
}

export default TermsCollectors