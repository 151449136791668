import React from 'react'
import GreenBackDrop from '../common/Green'
import { Column, Container, Row } from '../common/AltCard'

const WhoWeAre = () => {

  const textSize = {
    sm: 24,
    md: 28,
    lg: 48,
  };
  return (
    <GreenBackDrop bgCol='primary'>

    <Container>
      <div className='xl:py-[3rem]'>
        <Row>
          <Column>
            <div className='space-y-6 lg:my-10 lg:pr-8' >
              <h1 className='text-5xl lg:text-6xl font-extrabold mb-4'>Who we are</h1>
              <p className='text-base leading-10 font-medium'>
                Pakam Technology is a platform as a service (PaaS) company that
                utilizes software applications and data to promote a circular
                economy and achieve sustainability through recycling and waste
                management. We focus on using technology to protect the
                environment, empower people, and generate profit. Our solution
                also aligns with achieving the 2030 Sustainable Development
                Goals (SDGs).
              </p>
            </div >
          </Column>
          <Column>
            <div className='flex p-0 mt-8 lg:mt-0 w-full h-full'>
              <iframe
                className="aspect-video rounded-xl w-full "
                src="https://www.youtube.com/embed/kXoy8TbaVWE"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div >
          </Column>
        </Row>        
      </div>

      </Container>
        
    </GreenBackDrop>

  )
}

export default WhoWeAre
