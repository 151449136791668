import React from 'react'
import ProductHero from '../../../components/products/ProductHero'
import ProductContent from '../../../components/products/ProductContent'
import KPIContent from '../../../components/products/KPIContent'
import { KPIFeaturesInfo, earnarticles, earncontentarticles } from '../../../utils/data/data'

const Earn = () => {
  return (
    <div>
      <ProductHero
        title={'Earn-as-You Waste (Pakam Household).'}
        data={earnarticles}
        />
      <ProductContent data={earncontentarticles}/>
      <KPIContent data={KPIFeaturesInfo}/>
    </div>
  )
}

export default Earn
