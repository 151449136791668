import React from 'react'

interface ButtonProp{
    icons?: boolean;
    weight?: boolean;
    primary?: boolean;
    white?: boolean;
    width?: boolean;
    className?: string;
    isBorder?: boolean;
    content:string
  }
const Button = ({content, icons, isBorder, className, weight, primary, white, width,}:ButtonProp) => {
  return (
    <button className={`
        ${icons ? `flex items-center justify-center` : ""}
        rounded-[59.65px]
        px-[46.5px] py-[11px]
        outline-none cursor-pointer text-[16px]
        transition-all 
        border-[${!isBorder ? "59.65px" : "10px"}]
        bg-[${primary ? "#008300" : "#FFFFFF"}]
        text-white
        text-[${white ? "#fff" : "#008300"}]
        hover:bg-white
        font-[${weight ? '500' : '700'}]
        hover:bg-[${primary ? "#145C53" : "#DCEBE3"}]
        w-[${width ? "" : "100%"}]
    `}>
      {content}
    </button>
  )
}

export default Button


