import React from 'react'
import BannerSlide from '../common/BannerSlide'
import { featured } from '../../utils/data/data'

const AsFeatured = () => {
  return (
    <div>
      <BannerSlide
      // speed={-2}
      title="As featured in:"
      imgUrl={featured}
      classname="object-contain"
      direction="right"
    //   width={180}
    //   height={55}
    />
    </div>
  )
}

export default AsFeatured
