import React from 'react'
import { Container } from '../common/AltCard'

const Effortless = () => {
  return (
    <div data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-out"
    data-aos-duration="1500" className='relative z-[-1]'>
      <img className='absolute top-[5%] lg:w-[400px] left-[2%] z-[-1] ' src="/svg/dottedMat.svg" alt="dotted mat" />
      <img className='absolute top-[25%] right-[4%] z-[-1] lg:w-[350px]' src="/svg/dottedMat.svg" alt="dotted mat" />
      <Container>
        <div className='w-full flex justify-center items-center flex-col py-[4rem]'>
            <h1 className='text-center text-[#35393F] font-Rob font-[800] text-[28px] leading-[28px] md:text-[48px] md:leading-[45px]'>Effortless <span className='text-[#005A00]'>Recycling</span><br /> Management</h1>
            <p className='text-center font-int font-[500] text-[16px] text-[#7A828F] my-4 md:my-8'>Schedule pickups, earn rewards, and connect with others—all in one place.</p>
            <img className='w-full md:w-[90%] lg:w-[80%] mt-10' src="/img/efforless.png" alt="effortless" />
        </div>      
      </Container>      
    </div>


  )
}

export default Effortless