import { ExpertReviewData } from "../../utils/data/data";
import { CardContainer, Container } from "../common/AltCard";
import TestimonialCard from "./TestimonialCard";


const ExpertReview = () => {
  return (
    <div className="bg-secondary">
      <Container>
        <CardContainer>
          {ExpertReviewData.map((testimonial, index) => {
            return (
              <TestimonialCard {...testimonial} key={index}></TestimonialCard>
            );
          })}
        </CardContainer>        
      </Container>

    </div>
  );
};

export default ExpertReview;
