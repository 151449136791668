import React, { useEffect, useRef } from 'react'
import GreenBackDrop from '../common/Green'
import { serve } from '../../utils/data/data'
import Card from '../common/Card'
import { Container } from '../common/AltCard'
import { Link, useNavigate } from 'react-router-dom'
// import VanillaTilt from 'vanilla-tilt'
import { FaArrowRightLong } from "react-icons/fa6";

interface serveCardprops{
  url:string;
  title: string;
  desc:string;
  icon: string;
  bgColor:string
}

const WhoWeServe = () => {
  const navigate = useNavigate()
  // const serveTilt = useRef<Array<HTMLDivElement>>([]);

  const ServeCard = ({url, title, desc, icon, bgColor}:serveCardprops) =>{
    return(
      <div className='serve-card'>
        <div style={{backgroundColor:bgColor}} className={`h-[250px] md:h-[220px] rounded-[24px] px-2 py-4 flex flex-col justify-between md:justify-normal gap-6 `}>
          <div className='flex w-full justify-between items-center'>
            <h2 className='font-int text-[#333333] font-[700] text-[24px] w-[80%] leading-[28px]'>{title}</h2>
            <img src={icon} className='w-[55px] h-[55px]' alt="icon" />
          </div>
          <p className='text-[#222D33] font-int font-[500] text-[14px] lg:w-[97%]'>{desc}</p>

          <Link className='md:hidden w-full flex items-center gap-6 font-int font-[500] text-[14px]' to={url}>Read more <FaArrowRightLong/></Link>
        </div>
        <Link to={url} className='serve-btn hidden  bg-[#002800] rounded-[18px] py-3 px-6 md:flex justify-between items-center'>
          <p className='text-white font-int text-[14px]'>Read More</p>
          <FaArrowRightLong color={'#fff'}/>
        </Link>
      </div>
    )
  }

  // useEffect(() => {
  //   return VanillaTilt.init(serveTilt.current, {
  //     reverse: true,
  //   });
  // }, []);
  return (
      <Container>

      <div data-aos="fade-up" data-aos-anchor-placement="top-center" data-aos-easing="ease-out"
     data-aos-duration="1000" className='w-full grid gap-8 grid-col-1 md:grid-cols-2 lg:grid-cols-4 mt-8 bg-primary rounded-[16px] p-6'>
        <div className='w-full h-full md:flex justify-center items-center hidden'>
           <h4 style={{letterSpacing:'0.5px'}} className='text-white font-int font-bold text-left text-lg md:text-5xl'>Who we serve</h4>          
        </div>
 

          {/* <div className='w-full grid gap-6 grid-col-1 lg:grid-cols-3 mt-8'> */}
              {
                serve.map((item, index)=>
                <div
                  key={index}               
                  // ref={(ref) => (serveTilt.current[index] = ref!)} 
                  >
                  <Link 
                    
                    to={item?.linkUrl || '/'}>
                      <ServeCard
                        title={item?.title ?? ''}
                        icon={item.imgUrl}
                        desc={item.text ?? ''}
                        bgColor={item.color??''}
                        url={item?.linkUrl ?? '/'}

                      />
                  </Link>
                </div>


                ) 
              }
          {/* </div> */}

        </div>        
      </Container>
  )
}

export default WhoWeServe
