import React from 'react'
import { Container } from '../common/AltCard'
import { Link } from 'react-router-dom'
import { FaArrowRight } from "react-icons/fa6";

const MarketPlace = () => {
  return (
    <Container>
      <div data-aos="zoom-in" data-aos-anchor-placement="top-center" data-aos-easing="ease-out"
     data-aos-duration="1000" className='lg:px-[3rem]'>
        <div className='w-full bg-[#F8FAE3] rounded-xl md:rounded-[32px] my-10 py-[32px] px-4 md:px-[60px] lg:px-[80px] flex flex-col md:flex-row'>
            <div className='w-full md:w-[60%] space-y-6'>
                <h1 className='font-Rob font-[800] text-[#35393F] text-[32px] md:text-[48px] leading-8 md:leading-12 text-center md:text-left'>We're a digital <br />marketplace</h1>
                <p className='font-[500] font-int text-[14px] md:text-[16px] text-[#7A828F] lg:w-[87%] text-center md:text-left'>Pakam is dedicated to achieving waste scarcity through innovative recycling solutions by connecting waste collectors with generators. With Pakam, you can earn rewards from your recyclable waste while contributing to a cleaner environment, all from the comfort of your home.</p>
                <div className='flex justify-center md:justify-normal gap-3 md:mt-8'>
                  <Link className='text-[#35393F] font-[600] font-Rob text-[12px] ' to='/about'>Learn more</Link>
                  <FaArrowRight/>
                </div>
                
            </div>
            <div className='w-full md:w-[40%] flex justify-center md:justify-end mt-4 md:mt-0'>
              <img className='h-[300px]' src="/img/Picturemarket.png" alt="market place" />
            </div>            
        </div>        
      </div>


    </Container>
  )
}

export default MarketPlace