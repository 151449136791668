import React from 'react'

const PrivacyCollector = () => {
  return (
    <div className=" bg-secondary">
    <div className='pt-10 pl-10px pr-0'>
      <div>
        <p className="pt-4">
          Pakam Operations (Company Registration Number 1796077) located at
          127 Ogunlana Drive, Surulere Lagos, Nigeria is the controller of
          personal data of collector and has appointed a Data Protection
          Officer <a className='text-[blue]' href="mailto:privacy@pakam.ng">(privacy@pakam.ng)</a>.
          The term "us" or "we" refers to the owner of the Pakam app, Pakam
          Operations, a private limited company, founded in the Federal
          Republic of Nigeria.
        </p>
      </div>
      <div>
        <h4 className='text-primary text-lg font-medium mb-2 mt-5'>1. Personal data we process</h4>
        <p>
          <ul className='mt-[7px] py-[3px] px-[40px]'>
            <li style={{listStyleType:'disc'}}>Name, e-mail, phone number, place of residence.</li>
            <li style={{listStyleType:'disc'}}> Geolocation of collector and driving routes</li>
            <li style={{listStyleType:'disc'}}>
              Information about vehicles (including registration number).
            </li>
            <li style={{listStyleType:'disc'}}>
              Collector's engagement and interactions on the marketplace and
              driver ratings (including Driver Score).
            </li>
            <li style={{listStyleType:'disc'}}>
              Data about criminal convictions and offences. The financial data
              of providing transportation services is not considered as
              personal data, because collector provide services in the course
              of economic and professional activities.
            </li>
            <li style={{listStyleType:'disc'}}>
              Communication and correspondence records: such as when the
              Collector engages with our in-app chat ("Send a Message"
              option), or speak with our customer service agents, the date and
              time of the communication, and its content.
            </li>
          </ul>
        </p>
      </div>
      <div>
        <h4 className='text-primary text-lg font-medium mb-2 mt-5'>2. Purposes of the processing</h4>
        <p>
          <ul className='mt-[7px] py-[3px] px-[40px]'>
            <li style={{listStyleType:'disc'}}>
              We collect and process personal data for the purpose of
              connecting Generators with Collectors to help them dispose off
              their waste smartly and more efficiently.
            </li>
            <li style={{listStyleType:'disc'}}>
              {" "}
              Geolocation and Collection routes are processed to analyse the
              geographical area and give suggestions to the collector. If you
              do not want to disclose your geolocation for generator, you must
              close the Pakam app or indicate in the Pakam app that you are
              offline and currently are not providing Collection services.
            </li>
            <li style={{listStyleType:'disc'}}>
              {" "}
              Driver's license, profession, identity documents and criminal
              convictions and offences are processed to determine the
              compliance with the legal requirements and the suitability of
              pursuing a profession as a collector.
            </li>
            <li style={{listStyleType:'disc'}}>
              {" "}
              Pakam app displays driver's photo, name and vehicle details for
              the generators to identify collector and vehicle.
            </li>
            <li style={{listStyleType:'disc'}}>
              {" "}
              Customer support data and correspondence is collected for the
              purposes of feedback and resolving disputes and service quality
              issues.
            </li>
            <li style={{listStyleType:'disc'}}>
              {" "}
              Personal data related to instant messages directly in the Pakam
              app are processed in order to provide assistance and customer
              support, including where we need to resolve any dispute between
              collectors and generators.
            </li>
            <li style={{listStyleType:'disc'}}>
              {" "}
              We collect and use information about a collector’s engagement
              and interaction on the marketplace, and their ratings (including
              Collectors Score) to encourage user safety, ensure collector
              comply with the Collectors Terms, and make sure we’re providing
              a quality and enjoyable service to everyone. Where the collector
              Score drops below a specified threshold, we may issue the
              collector with a notification or request to, for example, digest
              some further guidance. If your score continues to drop, you will
              be temporarily suspended from accessing the Pakam platform. You
              may appeal any decision to suspend your account.{" "}
            </li>
          </ul>
        </p>
      </div>
      <div>
        <h4 className='text-primary text-lg font-medium mb-2 mt-5'>3. Legal basis</h4>
        <p>
          <ul className='mt-[7px] py-[3px] px-[40px]'>
            <li style={{listStyleType:'disc'}}>
              Personal data is processed for the performance of the contract
              concluded with the collector. The prerequisite for the use of
              the Pakam services is the processing of driver's identification
              and geolocation data.
            </li>
            <li style={{listStyleType:'disc'}}>
              Personal data may be processed on the ground of legitimate
              interest , including things like investigating and detecting
              fraudulent payments.
            </li>
            <li style={{listStyleType:'disc'}}>
              {" "}
              Data regarding the criminal convictions and offences is
              processed for compliance with a legal obligation.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h4 className='text-primary text-lg font-medium mb-2 mt-5'>4. Recipients</h4>
        <p>
          <ul className='mt-[7px] py-[3px] px-[40px]'>
            <li style={{listStyleType:'disc'}}>
              Your personal data is only disclosed to generators, whose
              schedule has been accepted by you. Generators will see driver's
              name, vehicle, phone number, photo and geolocation data.{" "}
            </li>
            <li style={{listStyleType:'disc'}}>
              •losed to the Pakam Operations local subsidiaries,
              representatives, affiliates, agents etc). Processing of personal
              data by will occur under the same conditions as established in
              this privacy notice.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h4 className='text-primary text-lg font-medium mb-2 mt-5'>5. Security and access</h4>
        <p>
          <ul className='mt-[7px] py-[3px] px-[40px]'>
            <li style={{listStyleType:'disc'}}>
              Any personal data collected in the course of providing services
              is transferred to and stored in the data centres of digital
              ocean. Only authorised employees of Pakam Operations and
              partners have access to the personal data and they may access
              the data only for the purpose of resolving issues associated
              with the use of the services (including disputes regarding
              collection services).
            </li>
            <li style={{listStyleType:'disc'}}>
              Pakam Operations and partners can access personal data to the
              extent necessary to provide customer support in the respective
              Area{" "}
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h4 className='text-primary text-lg font-medium mb-2 mt-5'>6. Processing generators personal data</h4>
        <p>
          <ul className='mt-[7px] py-[3px] px-[40px]'>
            <li style={{listStyleType:'disc'}}>
              You may not process the personal data of generator without the
              permission of us. You may not contact any generator or collect,
              record, store, grant access, use or cross-use the personal data
              provided by the collector or accessible to you via the Pakam app
              for any reason other than for the purposes of fulfilling the
              collection services.
            </li>
            <li style={{listStyleType:'disc'}}>
              • You must comply with the rules and conditions for processing
              of personal data of generator as set forth in the Privacy Policy
              for Generator{" "}
              <a className='text-[blue]' href="https://pakam.ng/privacy-policy" target="_blank">
                pakam.ng privacy-policy
              </a>
              . If you violate the requirements for the processing of personal
              data of generator, we may terminate your collection account and
              claim damages from you.
            </li>
          </ul>
        </p>
      </div>

      <div>
        <h4 className='text-primary text-lg font-medium mb-2 mt-5'>
          7. Access, correction, retention, deletion and data portability
        </h4>
        <p>
          <ul className='mt-[7px] py-[3px] px-[40px]'>
            <li style={{listStyleType:'disc'}}>
              Personal data can be viewed and corrected in Pakam Collectors
              Portal.
            </li>
            <li style={{listStyleType:'disc'}}>
              {" "}
              Your personal data will be stored as long as you have an active
              collector's account. If your account will be closed the personal
              data will be stored for additional 3 year period.
            </li>
            <li style={{listStyleType:'disc'}}>
              Data necessary for accounting purposes shall be stored for 7
              years.
            </li>
            In the event of suspicions of a administrational or criminal
            offence, fraud or false information, the data shall be stored for
            10 years
            <li style={{listStyleType:'disc'}}>
              {" "}
              In the event of disputes, the data shall be retained until the
              claim is satisfied or the expiry date of such claims.
            </li>
            <li style={{listStyleType:'disc'}}>
              {" "}
              We respond to the request for deleting and transferring personal
              data submitted by an e-mail within a month and specify the
              period of data deletion and transfer.
            </li>
            <li style={{listStyleType:'disc'}}> Recorded calls shall be stored for a period of 1 year.</li>
          </ul>
        </p>
      </div>

      <div>
        <h4 className='text-primary text-lg font-medium mb-2 mt-5'>8. Dispute resolution</h4>
        <p>
          <ul className='mt-[7px] py-[3px] px-[40px]'>
            <li style={{listStyleType:'disc'}}>
              • Disputes relating to the processing of personal data are
              resolved through customer support{" "}
              <a className='text-[blue]' href="mailto:info@pakam.ng"> (info@pakam.ng)</a> or by
              contacting Pakam Operations Data Protection Officer
              <a className='text-[blue]' href="mailto:privacy@pakam.ng"> (privacy@pakam.ng)</a>
            </li>
          </ul>
        </p>
      </div>

      <p className="text-base font-bold pt-4 pb-8">
        This privacy notice was last updated on 10 September, 2022
      </p>
    </div>
  </div>
  )
}

export default PrivacyCollector