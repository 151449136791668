


import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { animated } from '@react-spring/web';
import { useSpring } from '@react-spring/core';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

interface PopModel {
  JobTitle?: string;
  DepartmentMission?: Array<[]>;
  Responsibility: Array<[]>;
  Requirement: Array<[]>;
  Description?: string;
}

interface ModalProps{
    // openModal: ()=>void;
    popupContent?: any;
    open: boolean;
    onClose: ()=>void;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%', // Increase the width as needed
  maxHeight: '80vh', // Set a maximum height for vertical scrolling
  overflowY: 'auto', // Enable vertical scrolling if content exceeds maxHeight
  bgcolor: 'background.paper',
  borderRadius: '20px',
  boxShadow: 24,
  p: 4,
};

 const TopModal:React.FC<ModalProps> = ({popupContent, open, onClose}) => {
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
          <div className=" flex justify-end
                  self-end
                   
                  mb-6
                  bg-Black font-extrabold">
          <button className="cursor-pointer" onClick={onClose}
>
            <img src="/images/png/close.png" alt=""  />
          </button>
        </div>
            
        <>
          {popupContent.map(
            ({
              JobTitle,
              DepartmentMission,
              Responsibility,
              Requirement,
              Description,
            }: PopModel) => (
              <>
                <div className="flex justify-between items-center">
                  <h3 className="font-bold text-lg lg:text-xl text-primary">
                    {JobTitle}
                  </h3>

                  <button
                    type="reset"
                    onClick={onClose}
                    className=" text-xs pt-2.5 pb-2.5 pr-5 pl-5 rounded-2xl text-primary hover:bg-primary  border-primary border hover:text-white transition ease-in-out delay-100 "
                  >
                    close
                  </button>
                </div>

                <>
                  <h5 className="text-primary font-bold text-base lg:text-lg my-3">
                    Description
                  </h5>

                  <p className="text-xs lg:text-sm leading-7">{Description}</p>
                </>

                {DepartmentMission && (
                  <>
                    <h5 className="text-primary font-bold text-base lg:text-lg my-3">
                      Department Mission :
                    </h5>
                    <div style={{listStyleType: 'initial'}} className="`text-xs lg:text-sm pl-4">
                      {DepartmentMission.map((el) => (
                        <li>{el}</li>
                      ))}
                    </div>
                  </>
                )}
                <h5 className="text-primary font-bold text-base lg:text-lg my-3">
                  Responsibilities
                </h5>
                <div style={{listStyleType: 'initial'}} className="`text-xs lg:text-sm pl-4">
                  {Responsibility.map((el) => (
                    <li>{el}</li>
                  ))}
                </div>

                <h5 className="text-primary font-bold text-base lg:text-lg my-3">
                  Requirements
                </h5>
                <div style={{listStyleType: 'initial'}} className="`text-xs lg:text-sm pl-4">
                  {Requirement.map((el) => (
                    <li>{el}</li>
                  ))}
                </div>
              </>
            )
          )}
        </>

          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default TopModal