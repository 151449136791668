import React from 'react'
import Banner from '../../components/common/Banner'
import Publications from '../../components/publication'

const Publication = () => {
  return (
    <div>
        <Banner para='Latest Updates' title='Publications & Podcast'/>
      <Publications/>
    </div>
  )
}

export default Publication
