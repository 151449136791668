import React from 'react'
import { Container } from '../common/AltCard'
import * as fbq from '../../lib/fbpixel';
import axios from 'axios';

const Recycletowork = () => {

    const stagingEnpoint = 'https://stagingapi.pakam.ng/api/facebook/notify'

  
    const handleDownloadAndroid= async() => {
      fbq.event('Download', { device: 'Andriod' })
      
      try {
        const res = await axios.post(stagingEnpoint, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error)
      }
    }
  
    const handleDownloadIOS= async() => {
      fbq.event('Download', { device: 'IOS' })
      
      try {
        const res = await axios.post(stagingEnpoint, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error)
      }
    }

  return (
    <Container>
           <div data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-easing="ease-out"
     data-aos-duration="1000" className='w-full bg-[#F8FAE3] rounded-lg md:rounded-[32px] my-10 py-[32px] md:px-[60px] lg:px-[80px] flex flex-col justify-center items-center '>
              <h1 className='text-[#005A00] font-[800] text-[24px] md:text-[48px] lg:text-[50px] text-center  tracking-[-4%] leading-[50px] font-Rob'>Put your recylables to work</h1>
              <p className='text-[#444951] font-[400] text-[20px] md:text-[36px] text-center font-Rob '>Earn and save the environment</p>
              <p className=' text-[#0F172A] text-[14px] font-[400] py-6 font-Rob'>Download the Pakam Household app today</p>
              <div className='flex space-x-10'>
                     <a  href="https://play.google.com/store/apps/details?id=com.pakamcustomer">
                      <button onClick={handleDownloadAndroid}>
                        <img className='md:h-[50px] md:w-[180px] h-[40px] w-[130px]' src="/img/get-it-on-google-play-badge.png" alt="" />
                      </button></a>
                    <a  href="https://apps.apple.com/ng/app/pakam-household-recycling-app/id1539296957">
                      <button onClick={handleDownloadIOS}>
                        <img className='md:h-[50px] md:w-[180px] h-[40px] w-[130px]' src="/img/app store badge.png" alt="" />
                      </button></a>

                </div>
           </div>     
    </Container>

  )
}

export default Recycletowork