import React from 'react'
import { Link } from 'react-router-dom'

interface CardProps{
    width: string
    height: string
    read?: boolean
    url?: string
    title?: string
    desc?: string
    img:string
    w_img?: string
    h_img?:string
}

const Card = ({url, w_img, h_img, width, height, read, img, title, desc}: CardProps) => {
  return (
    <div style={{height:`${height}`, width:`${width}`}} className={`bg-white px-4 py-8 rounded-xl grid items-start justify-start gap-2 cursor-pointer`}>
      <img src={img} alt="" style={{height:`${w_img}px`, width:`${h_img}px`}} />
      <h6 style={{color:'rgba(0, 87, 0, 1)'}} className='font-[Raleway] font-[700] text-[18px]'>{title}</h6>
      <p style={{color:'rgba(34, 45, 51, 1)'}} className='font-[Raleway] text-[16px]'>{desc}</p>

      {read && <Link style={{color:'rgba(0, 0, 0, 1)'}} className='font-[Raleway] font-[700] text-[12px]' to={`${url}`} >Read more</Link>}
      
    </div>
  )
}

export default Card
