import React from 'react'
import ProductHero from '../../../components/products/ProductHero'
import { Smartarticles, kpiFeatures, smartContentarticles } from '../../../utils/data/data'
import ProductContent from '../../../components/products/ProductContent'
import KPIContent from '../../../components/products/KPIContent'

const Smart = () => {
  return (
    <div>
      <ProductHero
        title={'Pakam Smart Enforcement.'}
        data={Smartarticles}
        />
        <ProductContent data={smartContentarticles}/>
        <KPIContent data={kpiFeatures}/>
    </div>
  )
}

export default Smart
