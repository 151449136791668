import React, {ReactNode} from 'react'
import Marquee from 'react-fast-marquee';
interface BannerProps{
    title: string;
    classname: string;
    imgUrl: Array<string>;
    direction?: undefined | "left" | "right" | "up" | "down" ;
}
const BannerSlide : React.FC<BannerProps> = ({title, imgUrl, classname, direction}) => {
  return (
    <div className=' w-full py-8'>
        <h1 className='font-[600] text-center text-[16px] md:text-2xl text-[#35393F] font-int '>{title}</h1>

        <div className="mt-8">
        <Marquee
          gradient={true}
        //   gradientColor={[248, 251, 251]}
          direction= {direction}
          // speed={speed}
        >
          <div
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-8"
            className='flex justify-center items-center'
          >
            {imgUrl.map((el, i) => (
              <img
                key={i}
                src={el}
                className={`w-[200px] h-[64px] ${classname}`}
              />
            ))}
          </div>
        </Marquee>
      </div>
      
    </div>
  )
}

export default BannerSlide
