import React from "react";
import { WhyPakam } from "../../utils/data/data";
import { ParentWorkComponent } from "../about/component";

export const Pakam = () => {
  const size = { width: 120, height: 120 };
  return (
    <ParentWorkComponent heading="Why Pakam?" children={WhyPakam} size={size} />
  );
};
