import React, { useEffect } from 'react'
import WhoWeAre from '../../components/about/WhoWeAre'
import WhatWeDo from '../../components/about/WhatWeDo'
import WhoWeServe from '../../components/home/WhoWeServe'
import Problems from '../../components/about/Problems'
import MissionVision from '../../components/about/MissionVision'
import AsFeatured from '../../components/about/AsFeatured'
import Impact from '../../components/about/Impact'
import Value from '../../components/about/Value'
import Awards from '../../components/about/Awards'
import SDG from '../../components/about/SDG'

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <WhoWeAre/>
      <WhatWeDo/>
      <MissionVision/>
      <Problems/>
      <Impact/>
      <Value/>
      <SDG/>
      <Awards/>
      <AsFeatured/>
    </div>
  )
}

export default About
