import React from "react";
import { CardContainer, Container } from "../common/AltCard";
import { Review } from "../../utils/data/data";
import TestimonialCard from "./TestimonialCard";


const Testimonial = () => {
  return (
    <div className="bg-secondary">
      <Container>
        <CardContainer>
          {Review.map((testimonial, index) => {
            return (
              <TestimonialCard {...testimonial} key={index}></TestimonialCard>
            );
          })}
        </CardContainer>        
      </Container>

    </div>
  );
};

export default Testimonial;
