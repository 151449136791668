import React, { useEffect, useState } from 'react'
import CookiePolicy from '../../components/cookie/CookieSection'
import {PageBanner} from '../../components/common/PageBanner'

const Cookies = () => {
  const [tab, setTab] = useState('cookies')
  return (
    <div className='z-[-1] pt-16'>
    <PageBanner text={'Cookie Policy for Pakam'}>
        <button className={`px-[0.25rem] ${tab==='cookies'? 'border-b border-[4px] text-white':'text-white opacity-50'}`}>Cookies</button>
    </PageBanner> 
    <CookiePolicy/>
</div>
  )
}

export default Cookies