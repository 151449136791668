import React from 'react'
import { householdSupport, recyclerSupport } from '../../utils/data/data';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Container } from '../common/AltCard';
import moment from 'moment';

const Details = () => {
    const day = moment().format("dddd");
    const time = moment().format("MMMM Do YYYY");
  
    const router = useNavigate();
    const [searchParams] = useSearchParams();
    const id = searchParams.get('id');
    const type = searchParams.get('type');
  
    function findSupportItemById(array: any[], id: any) {
      return array.find((item) => item.id === Number(id));
    }
  
    let supportItem;
    let breadcrumb: { label: string; link: string } = {
      label: "",
      link: "",
    };
  
    if (type === "household") {
      supportItem = findSupportItemById(householdSupport, id);
      breadcrumb = { label: "Household User", link: "/support?source=household" };
    } else if (type === "recycler") {
      supportItem = findSupportItemById(recyclerSupport, id);
      breadcrumb = {
        label: "Recycler",
        link: "/support?source=recycler",
      };
    }
  return (
    <>
      <Container>
        <div className="my-10">
          <div>
            <div className="flex space-x-2 text-gray opacity-50 mb-8">
              <Link to="/support">
                <p className="text-xs md:text-base cursor-pointer">
                  Support {">"}
                </p>
              </Link>
              <Link to={breadcrumb?.link}>
                <p className="text-xs md:text-base cursor-pointer ">{`${breadcrumb?.label} >`}</p>
              </Link>
              <p className="text-xs md:text-base cursor-pointer">
                {supportItem?.title}
              </p>
            </div>
          </div>
          <h6 className='font-bold text-primary text-xl md:text-2xl lg:text-4xl md:h-14 h-10'>{supportItem?.title}</h6>
          <h6 className='text-sm md:text-lg text-dark-gray pt-7 md:pt-0'>{supportItem?.subtitle}</h6>

          <div className='flex flex-col items-center justify-center w-full md:grid md:grid-cols-2 lg:grid-cols-3'>
            {supportItem?.steps?.map((step: any) => {
              return (
                <div className="w-[90%] pt-5 pb-5">
                  <div>
                    <p className="font-medium text-sm pb-7 leading-8">
                      {step.info}
                    </p>
                  </div>
                  <img className='w-full h-[220px] object-contain md:w-[50%] md:h-[250px] lg:w-[165px] lg:h-[300px] ' src={step.imgUrl} alt="img" />
                </div>
              );
            })}
          </div>
          <div className=" pt-5 font-medium w-[70%] md:w-[50%]">
            {supportItem?.NB}
          </div>
        </div>
      </Container>
    </>
  )
}

export default Details
