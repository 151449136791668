import React from 'react'


const KPICard = ({ imgSrc = "", text = "", imgAlt = "", ...props }) => {
    return (
        <>
          {/* <OverallContainer>
            <div>
              <ImageContent>
                <ImageContainers>
                  <ImageImage src={imgSrc} alt={imgAlt} height={150}></ImageImage>
                </ImageContainers>
              </ImageContent>
            </div>
            <P>{text}</P>
          </OverallContainer> */}
    
          <div>
            <div className="h-[160px] bg-primary flex items-center justify-center rounded-t-xl">
              <img
                src={imgSrc}
                alt={imgAlt}
                width={100}
                height={100}
                className="object-contain"
              />
            </div>
            <div className="h-[80px] border-primary border-2 border-solid flex items-center justify-center font-bold text-xs">
              <p className='text-primary p-9 text-center'>{text}</p>
            </div>
          </div>
        </>
      );
    };

export default KPICard