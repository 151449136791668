import React, { useState } from 'react'
import {Interview, NewSection, PressRelease} from '../../components/news/News'
import {PageBanner} from '../../components/common/PageBanner'

const News = () => {
  const [tab, setTab] = useState('news')
  return (
    <div className='pt-16'>
      <PageBanner isUpdate={true} para='Latest Updates' text={'What the news is saying about us'}>
        <button className={`px-[0.25rem] ${tab==='news'? 'border-b border-[4px] text-white':'text-white opacity-50'}`} onClick={()=>setTab('news')}>News & Media</button>
        <button className={`px-[0.25rem] ${tab==='press'? 'border-b border-[4px] text-white':'text-white opacity-50'}`}  onClick={()=>setTab('press')}>Press Release</button>
        <button className={`px-[0.25rem] ${tab==='interview'? 'border-b border-[4px] text-white':'text-white opacity-50'}`}  onClick={()=>setTab('interview')}>Interview</button>
      </PageBanner>

      {
        tab==='news'?<NewSection/>: tab === 'press'? <PressRelease/>:<Interview/>
      }
      
    </div>
  )
}

export default News
