import React, { useEffect, useRef, useState } from 'react';
import { Container } from '../common/AltCard';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import * as fbq from '../../lib/fbpixel';
import './ref.css'


import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { animated } from '@react-spring/web';
import { useSpring } from '@react-spring/core';
import { CircularProgress } from '@mui/material';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

interface PopModel {
  JobTitle?: string;
  DepartmentMission?: Array<[]>;
  Responsibility: Array<[]>;
  Requirement: Array<[]>;
  Description?: string;
}

interface ModalProps{
    // openModal: ()=>void;
    popupContent?: any;
    open: boolean;
    onClose: ()=>void;
}

interface InputProps {
  label: string;
  type: string;
  name: string;
  psuedo: string;
  value: string;
  onValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onPress?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  show?: boolean;
  error?: string;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
    const {
      children,
      in: open,
      onClick,
      onEnter,
      onExited,
      ownerState,
      ...other
    } = props;
    const style = useSpring({
      from: { opacity: 0 },
      to: { opacity: open ? 1 : 0 },
      onStart: () => {
        if (open && onEnter) {
          onEnter(null as any, true);
        }
      },
      onRest: () => {
        if (!open && onExited) {
          onExited(null as any, true);
        }
      },
    });
  
    return (
      <animated.div ref={ref} style={style} {...other}>
        {React.cloneElement(children, { onClick })}
      </animated.div>
    );
  });
  
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '80%', // Increase the width as needed
    maxHeight: '80vh', // Set a maximum height for vertical scrolling
    overflowY: 'auto', // Enable vertical scrolling if content exceeds maxHeight
    bgcolor: 'background.paper',
    borderRadius: '20px',
    boxShadow: 24,
    p: 4,
  };

const CommercialForm = ({new_data}:any) => {
    const ref_url = process.env.REACT_APP_REFERRAL_URL??''

const { id } = useParams<{ id: string }>();
const [showModal, setShowModal] = useState(false)
const [searchQuery, setSearchQuery] = useState<string>('')
const [open, setOpen] = useState<boolean>(false)
const menuRef = useRef<HTMLDivElement | null>(null)
const [loading, setLoading] = useState(false)
  const [userType, setUserType] = useState({
    children: [],
    slug:'',
    title:'',
    _id:''
  });
  const [acctTypes, setAcctTypes] = useState([]);
  const [comType, setComType] = useState('');
  const [formData, setFormData] = useState({
    comUserType: comType,
    orgName: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsCondition: false,
  });

  const [errors, setErrors] = useState({
    firstname: '',
    lastname: '',
    phoneNumber: '',
    email: '',
    password: '',
    confirmPassword: '',
    termsCondition: '',
  });


  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);

  const Trigger = async () => {
    try {
      const res = await axios.get(`${ref_url}/v2/user/account-types`);
      console.log(res.data);
      setUserType(res?.data?.data[1])
      setAcctTypes(res?.data?.data)
    } catch (error) {
      console.log(error);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneRegex = /^[0-9]{10,14}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    setLoading(true)
    e.preventDefault();
    const { comUserType, orgName, phoneNumber, email, password, confirmPassword, termsCondition } = formData;

    const newErrors = {
      firstname: comUserType ? '' : 'First Name is required',
      lastname: orgName ? '' : 'Last Name is required',
      phoneNumber: validatePhoneNumber(phoneNumber) ? '' : 'Invalid phone number',
      email: validateEmail(email) ? '' : 'Invalid email format',
      password: password.length >= 6 ? '' : 'Password must be at least 6 characters',
      confirmPassword: password === confirmPassword ? '' : 'Passwords do not match',
      termsCondition: termsCondition ? '' : 'You must accept the Terms and Conditions',
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error !== '');

    if (!hasErrors) {
      const params = {
        email,
        phoneNumber,
        "accountType":userType._id,
        password,
        "terms_condition":termsCondition,
        "organization":orgName,
        "organisationType":comUserType,
        "referralCode":id
    }

      console.log(params)

      try {
        const res = await axios.post(ref_url, params);
        console.log(res.data);
        setLoading(false)
        setShowModal(true)

      } catch (error) {
        setLoading(false)
        console.log(error);
      }


    }else{
        setLoading(false)
    }

    
  };

  const stagingEnpoint = 'https://stagingapi.pakam.ng/api/facebook/notify'

  
  const handleDownloadAndroid= async() => {
    fbq.event('Download', { device: 'Andriod' })
    
    try {
      const res = await axios.post(stagingEnpoint, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  const handleDownloadIOS= async() => {
    fbq.event('Download', { device: 'IOS' })
    
    try {
      const res = await axios.post(stagingEnpoint, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    Trigger();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };


  const handleChange = (value:string) => {
    setComType(value)
    setFormData({
      ...formData,
      comUserType:value
    })

    setOpen(false)
    
  };
  
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (menuRef.current && e.target instanceof Node) {
        if (!menuRef.current.contains(e.target)) {
          setOpen(false);
        }
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <>

          <form className='space-y-6' onSubmit={handleSubmit}>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                <div>
                    <p className="font-[500] font-int text-[14px] text-[#101928] ">Type of Commercial User</p>
                    <div ref={menuRef}  className="relative">
                    <input
                        onClick={()=>setOpen(true)}
                        className={`border outline-none border-[#D0D5DD] shadow-md p-3 placeholder:text-[14px] w-full rounded-md ${errors.firstname ? 'border-red-500' : ''}`}
                        type='text'
                        name='comUserType'
                        placeholder={"Select organization type"}
                        value={comType}
                        onChange={handleInputChange}
                    />
                        <div  className={`${open ? 'block' : 'hidden'} absolute w-full left-0 top-[3rem] z-50 bg-white border shadow-lg rounded-lg p-3`}>
                            <p className='font-semibold text-[14px] mb-3'>Select the type of Commercial Account you want to create</p>

                            <ul className='h-[300px] pt-2 '>
                                {
                                  new_data?.children.map((i:any, id:any)=>{
                                    return(
                                        <li style={{backgroundColor: comType === i.slug ? 'rgba(0, 131, 0, 0.07)':''}} className={`${comType === i.slug? 'rounded-md p-2':''} font-int font-[400] text-[14px] py-3 flex justify-between items-center` } onClick={()=>{
                                          setOpen(false)
                                          handleChange(i.slug)
                                          
                                        }}
                                        ><span>{i.title}</span> {comType === i.slug && <img src='/img/refTick.png'/>}</li>
                                    )
                                })                                 
                                }
                            </ul>

                        </div>
                    </div>
                    {errors.firstname && <p className="text-red-500 text-sm">{errors.firstname}</p>}
                </div>
                <div>
                    <p className="font-[500] font-int text-[14px] text-[#101928] ">Organisation Name</p>
                    <div className="relative">
                    <input
                        className={`border outline-none border-[#D0D5DD] shadow-md p-3 placeholder:text-[14px] w-full rounded-md ${errors.lastname ? 'border-red-500' : ''}`}
                        type='text'
                        name='orgName'
                        placeholder={"Enter your last name"}
                        value={formData.orgName}
                        onChange={handleInputChange}
                    />
                    {/* {type === 'password' && (
                        <button className="absolute right-3 top-3" onClick={onPress}>
                        {show ? <FaRegEyeSlash /> : <FaRegEye />}
                        </button>
                    )} */}
                    </div>
                    {errors.lastname && <p className="text-red-500 text-sm">{errors.lastname}</p>}
                </div>
                <div>
                    <p className="font-[500] font-int text-[14px] text-[#101928] ">Phone Number</p>
                    <div className="relative">
                    <input
                        className={`border outline-none border-[#D0D5DD] shadow-md p-3 placeholder:text-[14px] w-full rounded-md ${errors.phoneNumber ? 'border-red-500' : ''}`}
                        type='text'
                        name='phoneNumber'
                        placeholder={"Enter your phone number"}
                        value={formData.phoneNumber}
                        onChange={handleInputChange}
                    />
                    {/* {type === 'password' && (
                        <button className="absolute right-3 top-3" onClick={onPress}>
                        {show ? <FaRegEyeSlash /> : <FaRegEye />}
                        </button>
                    )} */}
                    </div>
                    {errors.phoneNumber && <p className="text-red-500 text-sm">{errors.phoneNumber}</p>}
                </div>
                <div>
                    <p className="font-[500] font-int text-[14px] text-[#101928] ">Email Address</p>
                    <div className="relative">
                    <input
                        className={`border outline-none border-[#D0D5DD] shadow-md p-3 placeholder:text-[14px] w-full rounded-md ${errors.email ? 'border-red-500' : ''}`}
                        type='text'
                        name='email'
                        placeholder={"Enter your email address"}
                        value={formData.email}
                        onChange={handleInputChange}
                    />
                    {/* {type === 'password' && (
                        <button className="absolute right-3 top-3" onClick={onPress}>
                        {show ? <FaRegEyeSlash /> : <FaRegEye />}
                        </button>
                    )} */}
                    </div>
                    {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>
                <div>
                    <p className="font-[500] font-int text-[14px] text-[#101928] ">Create Password</p>
                    <div className="relative">
                    <input
                        className={`border outline-none border-[#D0D5DD] shadow-md p-3 placeholder:text-[14px] w-full rounded-md ${errors.password ? 'border-red-500' : ''}`}
                        type= {showPassword?'text':'password'}
                        name='password'
                        placeholder={"Enter your password"}
                        value={formData.password}
                        onChange={handleInputChange}
                    />
                        <button type='button' className="absolute right-3 top-3" onClick={()=>setShowPassword(!showPassword)}>
                        {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}</button>
            
                    </div>
                    {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
                </div>
                <div>
                    <p className="font-[500] font-int text-[14px] text-[#101928] ">Confirm Password</p>
                    <div className="relative">
                    <input
                        className={`border outline-none border-[#D0D5DD] shadow-md p-3 placeholder:text-[14px] w-full rounded-md ${errors.confirmPassword ? 'border-red-500' : ''}`}
                        type= {showconfirmPassword ? 'text':'password'}
                        name='confirmPassword'
                        placeholder={"Enter your confirmed password"}
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                    />
                        <button type='button' className="absolute right-3 top-3" onClick={()=>setShowconfirmPassword(!showconfirmPassword)}>
                        {showconfirmPassword ? <FaRegEyeSlash /> : <FaRegEye />}</button>
            
                    </div>
                    {errors.confirmPassword && <p className="text-red-500 text-sm">{errors.confirmPassword}</p>}
                </div>

                
            </div>

            <div className='flex items-center gap-1'>
              <input className='checked:bg-primary' type="checkbox" name="termsCondition" checked={formData.termsCondition} onChange={handleInputChange} />
              <label className="ml-2 text-[14px] font-[600]">I accept Pakam’s Terms and Conditions</label>
              {errors.termsCondition && <p className="text-red-500 text-sm">{errors.termsCondition}</p>}
            </div>

            <div className='w-full flex justify-center'>
              <button type='submit' disabled={loading} className='mx-auto md:w-[60%] py-3 px-8 rounded-md bg-primary text-white font-[500] flex items-center justify-center gap-3'>
                <span>Create my account </span>
                
                {
                    loading && <span><CircularProgress size={20} color="inherit" /></span> 
                }
               
              </button>
            </div>
          </form>




      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={showModal}
        onClose={()=>setShowModal(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        {/* <Fade in={()=>setShowModal(false)}> */}
          <Box sx={style}>
          <div className=" flex justify-end
                  self-end
                   
                  mb-0
                  bg-Black font-extrabold">
          <button className="cursor-pointer" onClick={()=>setShowModal(false)}
>
            <img src="/images/png/close.png" alt=""  />
          </button>
        </div>
            
        <>
           <div className=' flex flex-col gap-8 justify-center items-center'>
                <img src="/img/mark-icon.png" className='w-[100px]' alt="" />
                <div>
                    <h2 className="font-int text-center font-[700] text-[#27272A] text-[24px]">Your account has been created successfully</h2>
                    <p className="font-int text-center font-[500] text-[#444951] text-[16px]">Download the Pakam app to login to your account</p>
                </div>
                <div className='flex gap-3'>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.pakamcustomer"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button onClick={handleDownloadAndroid}>
                                <img
                                src="/img/play-black.png"
                                alt="play store"
                                className='w-[120px] h-[40px]'
                                />                                
                            </button>

                        </a>

                        <a
                            href="https://apps.apple.com/ng/app/pakam-household-recycling-app/id1539296957"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button onClick={handleDownloadIOS}>
                                <img
                                src="/img/ios-black.png"
                                alt="app store"
                                className='w-[120px] h-[40px]'
                                />                                
                            </button>

                        </a>
                        </div>
           </div>
        </>

          </Box>
        {/* </Fade> */}
      </Modal>
    </>
  );
};

export default CommercialForm