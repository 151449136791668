import React from 'react'

interface CommentProps{
    icon:string;
    title:string;
    time:string;
}
const CommentTab = ({icon, title, time}:CommentProps) => {
  return (
    <div className='shadow-2xl rounded-xl p-2 w-[300px] md:w-[350px] bg-white space-y-1 md:space-y-1'>
        <img className='w-[30px] h-[30px] rounded-full' src={icon} alt="icon" />
        <p className='text-[#1D1D1D] font-int font-[600] text-[12px]'>{title}</p>
        <p className='text-[#1D1D1D] font-int font-[400] text-[10px]'>{time} MINS AGO</p>
    </div>
  )
}

export default CommentTab