import React from 'react'
import { recycledata } from '../../utils/data/data'
import { Container } from '../common/AltCard'
import { FaAngleRight } from "react-icons/fa6";
import CommentTab from '../common/CommentTab';
import { Link } from 'react-router-dom';

const Become = () => {
  return (
    <Container>
        <div data-aos="fade-up"  data-aos-easing="ease-in-out"
     data-aos-duration="2500" className='py-[4rem]'>
            <div>
                <h1 className='hidden md:block text-center text-[#35393F] font-Rob font-[800] text-[28px] md:text-[48px] leading-[45px]'>Become a Waste Collector</h1>
                <h1 className='md:hidden text-center text-[#35393F] font-Rob font-[800] text-[28px] md:text-[48px] leading-[45px]'>We connect you with waste collectors</h1>
            </div>

            <div className='flex flex-col md:flex-row gap-4 w-full justify-center mt-8'>
                <div className='space-y-4 rounded-[8px] bg-[#FDF1DC] flex flex-col justify-between items-center w-full md:w-[430px] pb-20 relative'>
                    <div className='flex flex-col space-y-4 w-full justify-center items-center'>
                        <div className='absolute top-[15%] left-[4%]'>
                            <CommentTab icon='/img/User1.png' title='You Confirmed a Drop-Off request' time='12'/>
                        </div>


                        <img src='/img/bec1.png' className='w-[300px]' alt='picture'/>

                        <p className='font-int font-[400] text-[#7A828F] text-[12px] text-center w-[70%]'>Get access to real-time information on the total amount of waste collected, helping you track your progress.</p>                        
                    </div>
                    


                    <Link to={'/products/earn'} className='w-[120px] p-2 rounded-[50px] text-[12px] border border-[#7A828F] flex gap-3 justify-center items-center hover:bg-primary hover:border-0 hover:text-white transition-all ease-in-out duration-500 hover:shadow-md hover:w-[130px]'><span>Learn More</span> <FaAngleRight/></Link>
                </div>
                <div className='space-y-4 rounded-[8px] bg-[#DCFCE7] flex flex-col justify-between items-center w-full md:w-[430px] pb-20 relative'>

                    <div className='flex flex-col space-y-8 w-full justify-center items-center'>
                        <div className='absolute bottom-[50%] lg:bottom-[50%] '>
                            <CommentTab icon='/img/User2.png' title='You have Pet bottle pickup request in Gbagada' time='30'/>
                        </div>
                        <img src='/img/bec2.png' className='w-[300px]' alt='picture'/>

                        <p className='font-int font-[400] text-[#7A828F] text-[12px]  text-center'>Get access to more waste materials.</p>                      
                    </div>

                    <Link to={'/products/earn'} className='w-[120px] p-2 rounded-[50px] text-[12px] border border-[#7A828F] flex gap-3 justify-center items-center hover:bg-primary hover:border-0 hover:text-white transition-all ease-in-out duration-500 hover:shadow-md hover:w-[130px]'><span>Learn More</span> <FaAngleRight/></Link>
                </div>

            </div>
        </div>        
    </Container>

  )
}

export default Become

