import React from 'react'
import ProductHero from '../../../components/products/ProductHero'
import ProductContent from '../../../components/products/ProductContent'
import KPIContent from '../../../components/products/KPIContent'
import { KPIFeaturesInfo, Payarticles, payContentarticles } from '../../../utils/data/data'

const Pay = () => {
  return (
    <div>
      <ProductHero
        title={'Pay-as-You Waste (Pakam Waste Disposal).'}
        data={Payarticles}
        />
        <ProductContent data={payContentarticles}/>
        <KPIContent data={KPIFeaturesInfo}/>
    </div>
  )
}

export default Pay
