type BenefitType = {
  img?: string;
  alt?: string;
  title?: string;
};
export const BenefitInfo: Array<BenefitType> = [
  {
    img: "./assets/images/schedule 2.svg",
    alt: "Hybrid Working",
    title: "Hybrid Working",
  },
  {
    img: "./assets/images/performance 1.svg",
    alt: "Career Development",
    title: "Career Development Working",
  },
  {
    img: "./assets/images/benefits-matching 1.svg",
    alt: "Global Benefits",
    title: "Global Benefits",
  },
  {
    img: "./assets/images/why-2 1.svg",
    alt: "Challenging Projects",
    title: "Challenging Projects",
  },
  {
    img: "./assets/images/Group.svg",
    alt: "Diverse Team",
    title: "Diverse Team",
  },
  {
    img: "./assets/images/share-options 1.svg",
    alt: "Enjoyable Workspace",
    title: "Enjoyable Workspace",
  },
];

export const Openings = [
  // {
  //   JobTitle: "Tech Lead",
  //   Duration: "Open till 30th March, 2022",
  //   Worktype: "Full-time",
  //   DepartmentMission: [
  //     "Become a part of the bigger picture and get ready to make important contributions to the development of innovative solutions.",
  //     "To build a quality and comprehensive error-free technology infrastructure, establish and maintain an effective operational environment with 99.99% run time, and deliver quality, prompt, cost-effective, and reliable technology services to serve the best impossible experience for customers..",
  //   ],
  //   Responsibility: [
  //     "Selecting technology stack",
  //     "Setting process and procedures",
  //     "Interview, hire, and train staff members",
  //     "Monitoring and Oversee company’s data",
  //     "Setting technology vision in the company",
  //     "Department budget Management",
  //     "Responsibility",
  //     "Visioning future technological requirements (Why and What)",
  //     "Planning technological transitions and migrations (How)",
  //     "Promoting and driving Agile method",
  //     "Maintain “Extreme Programming” (Paired programming, Test Driven Development, refactoring, etc.)",
  //     "Define research and development goals",
  //     "Promoting a collaborative and supportive culture of empowerment across the department",
  //     "Define, implement and maintain OKRs",
  //     "Close communication with the Product team, and other customer-focused teams",
  //     "Assisting CEO and People Operations team to develop a unified and consistent process for attracting, developing, motivating, and retaining talents",
  //     "Managing technology budgets and time frames.",
  //     "Staying on top of technology trends and developments.",
  //     "Ensuring technology practices comply with regulatory standards.",
  //     "Ensuring continuous improvement and delivery",
  //   ],
  //   Requirement: [
  //     "2+ years of experience in a technological management role",
  //     "6 years experience in a similar role in a high-tech environment",
  //     "Proven record of successful projects",
  //     "Strong communication skills",
  //     "Broad industry knowledge visioning the future",
  //     "Having your skin in the game to get things done",
  //     "Must have an understanding of the following technologies: React Native, Flutter, Go, Multiple Servers, Vue.js, iOS & Android, Kubernetes, and cloud computing.",
  //     "Familiar with Android Studio",
  //     "Understand how to build for IOS using REACT NATIVE",
  //     "Firm grasp of the JavaScript  language and its nuances, including ES6+ syntax",
  //     "Knowledge of functional and object-oriented programming",
  //     "Familiarity with native build tools, like XCode, Gradle Android Studio,",
  //     "Understanding of REST APIs, the document request model, and offline storage",
  //     "Experiences with automated testing suites, like Jest or Mocha",
  //     "Proven experience as a Full Stack Developer or similar role",
  //     "Familiarity with common stacks",
  //     "Knowledge of multiple front-end languages and libraries (e.g. HTML/ CSS, JavaScript, XML, jQuery)",
  //     "Knowledge of multiple back-end languages (e.g. C#, Java, Python) and JavaScript frameworks (e.g. Express.js, Nest.js, Node.js)",
  //     "Familiarity with databases (e.g. MySQL, MongoDB), web servers (e.g. Apache) and UI/UX design",
  //   ],
  // },

  // {
  //   JobTitle: "UX/UI Desginer",
  //   Duration: "Open till 30th March, 2022",
  //   Worktype: "Intern",
  //   Responsibility: [
  //     "The UX, UI, and visual design of the website by balancing the needs of our users, our design aesthetic, and the objectives of the business",
  //     "Collaborating with the Marketing/Tech teams to assess product requirements and translate them into compelling designs that optimize the user experience",
  //     "Building rapid high fidelity prototypes, landing pages, and marketing assets",
  //     "Creating iterative designs that meet project objectives and incorporate feedback, analytics, and user testing",
  //     "Confidently presenting design ideas and recommendations to the team",
  //     "Keeping abreast of current design tools, techniques, and technologies",
  //   ],
  //   Requirement: [
  //     "Related experience in Design (graphic, visual communications, interaction, product, etc)",
  //     "1+ years of experience in visual, eCommerce, brand, or marketing design",
  //     "A portfolio showcasing UX, UI, and visual design work for mobile and desktop",
  //     "Fluent in design tools such as Figma, Adobe Creative Suite, Sketch, and InVision",
  //     "Knowledge of how mocks translate to HTML, CSS, and JavaScript to deliver feasible designs",
  //     "Thrive in a fast-paced environment with limited resources by being self-motivated and working independently to deliver projects on time",
  //     "Ability to think critically and creatively",
  //     "Attention to detail",
  //   ],
  // },
  // {
  //   JobTitle: "Full-stack Engineer",
  //   Duration: "Open till 30th March, 2022",
  //   Worktype: "Intern",
  //   DepartmentMission: [
  //     "Become a part of the bigger picture and get ready to make important contributions to the development of innovative solutions.",
  //     "To build a quality and comprehensive error-free technology infrastructure, establish and maintain an effective operational environment with 99.99% run time, and deliver quality, prompt, cost-effective, and reliable technology services to serve the best impossible experience for customers..",
  //     "The role is to build optimized, scalable and modular software using technologies based. You are tasked with developing and coding back-end components and connecting applications to other web services.",
  //   ],
  //   Responsibility: [
  //     "Research and test new technologies",
  //     "Collaborating with others to build and develop a quality",
  //     "Monitoring and Oversee company’s data",
  //     "Managing users and user roles",
  //     "Detecting, announcing, and correcting errors",
  //     "Proposing new solutions",
  //     "Developing Back-end and Front on Node and React Native components and support new API’s",
  //     "Design, development and testing of new product features",
  //     "Collaborate with and review PRs of other team members",
  //     "Improve the reliability and robustness of our application",
  //     "Work on scalability and performance improvements",
  //     "Work together with the sales and support teams on requirements and issue",
  //     "Fluency in libraries such as Flask, SQLAlchemy, Click, requests, async, logging",
  //     "Solid knowledge of different tools such as Postgres, Redis, GCS, Alembic, mypy, pip, Sentry, Icepeak",
  //     "Applying Agile Design and DevOps principles",
  //   ],
  //   Requirement: [
  //     "1+ years of experience with Node.js and React Native",
  //     "Strongly experienced with Distributed systems and micro-services",
  //     "Solid experience in databases such as MongoDB, Postgres, MySQL, DynamoDB.",
  //     "Meaningful, real-world experience with any other of the following: React, Vue or similar",
  //     "Experience with messaging queues such as RabbitMQ",
  //     "A drive to overcome challenges and solve problems with a creative twist",
  //     "Having working experience with Git, and digital ocean",
  //   ],
  // },

  // {
  //   JobTitle: "Digital Marketer",
  //   Duration: "Open till 30th March, 2022",
  //   Worktype: "Intern",
  //   Responsibility: [
  //     "Be creative and actively think outside the box.",
  //     "Monitor and post on blogs, forums, and social networks; effectively communicate and build community through engaging content",
  //     "Manage social media accounts and schedule posts - employing tagging and use of visuals to increase presence and illustrate brand story",
  //     "Work collaboratively alongside the team to create a plan for monthly social media and stakeholder outreach strategies; assist with online outreach and promotion using Facebook, Instagram, LinkedIn, Twitter, newsletters, and more.",
  //     "Optimize website and social media presence; provide suggestions to management for improving customer experience on social platforms and internal processes.",
  //   ],

  //   Requirement: [
  //     "Must demonstrate strong cross-cultural competencies; respects and embraces diverse perspectives, identities, and cultural values",
  //     "Excellent verbal and written communication skills",
  //     "Solid understanding of social media tactics and community building",
  //     "Strong time management, planning, and execution skills",
  //     "Proficient with Google Platform applications (i.e. Docs, Sheets, Slides)",
  //     "Ability to work effectively on tight deadline schedules independently & collaboratively",
  //     "Innovative & resourceful",
  //     "Positive and enthusiastic attitude",
  //     "Keen attention to detail",
  //     "1+ years of experience in Canva",
  //   ],
  // },
  // {
  //   JobTitle: "Software Tester",
  //   Duration: "Open till 30th March, 2022",
  //   Worktype: "Intern",
  //   Responsibility: [
  //     "Assist in the development of end to end automated regression and performance test scripts",
  //     "Work closely with development teams to ensure the effectiveness and quality of the components and systems developed",
  //     "Test for a range of projects from and enhancements to strategic initiatives",
  //     "Develop, maintain and execute test cases, ensuring the quality and stability of the system throughout the project",
  //     "Generate testing completion metrics and analysis, as well as status read-outs to upper management",
  //     "Develop, implement, maintain, and enhance test plans, test scripts, traceability matrices, and test methodologies that ensure exhaustive testing of all assigned software systems to ensure compliance with software / system specifications.",
  //     "Create, maintain and modify test plans for new and existing software applications/releases that ensure comprehensive testing according to the software specifications.",
  //     "Anticipate potential software problems by creating test scenarios.",
  //     "Identify and analyze defects / test results and be able to deduct the chain of events leading to a failure.",
  //     "Coordinate the User Acceptance Testing (UAT) with the Business Owners and their UAT-responsible personnel.",
  //     "Ensure satisfactory service with customers at all levels (Business Analyst, Developers, Project Managers, and Business Owners).",
  //   ],
  //   Requirement: [
  //     "1+ years Experience in software testing.",
  //     "Knowledge of OOP, Data Structures, and Algorithms",
  //     "Ability to code in a functional or object-oriented programming language",
  //     "Driven to innovate",
  //     "Passionate about technology",
  //     "Great problem-solving skills",
  //     "Adaptable",
  //     "Experience with UI testing automation, specifically with Cypress is a plus",
  //     "Application of software development life cycle concepts",
  //     "Knowledge of Object-Oriented Programming",
  //     "Knowledge of the JavaScript Programming language",
  //     "Knowledge of the React framework",
  //     "Knowledge of Selenium Web Driver",
  //     "Knowledge of API testing (REST / SOAP)",
  //     "Knowledge of relational database principles (SQL / MySQL / MongoDB / SOQL).",
  //     "Bachelor's Degree qualification.",
  //   ],
  // },
  // {
  //   JobTitle: "Software Tester",
  //   Duration: "Open till 30th March, 2022",
  //   Worktype: "Contract",
  //   Responsibility: [
  //     "Review requirements, specifications and technical design documents to provide timely and meaningful feedback",
  //     "Create detailed, comprehensive and well-structured test plans and test cases",
  //     "Estimate, prioritize, plan and coordinate testing activities",
  //     "Identify test scenarios, correlate them to business requirements, and report on test coverage.",
  //     "Estimate, prioritize, plan, and coordinate testing activities",
  //     "Test integration of local and cloud components",
  //     "Identify, record, document thoroughly and track bugs",
  //     "Prioritize defects accurately based on impact on business goals",
  //     "Perform API tests using Postman",
  //     "Perform non-functional tests such as performance when the need arises.",
  //     "Prepare frequent status reports and updates with stakeholders",
  //     "Design, develop and execute automation scripts using open source tools",
  //     "Basic familiarity with DevOps concepts",
  //     "Develop a wide-range of SQL queries on relational database systems such as SQL Server",
  //     "Assist other projects as directed by the Test Lead, or QA Manager",
  //     "Passion for quality, detail oriented, and know how to break software by finding bugs",
  //   ],
  //   Requirement: [
  //     "A minimum of 3 years' Proven work experience in software development",
  //     "Hands-on experience with automated testing tools e.g Selenium, Appium, Cypress",
  //     "Proven work experience in software quality assurance",
  //     "Strong knowledge of software QA methodologies, tools and processes",
  //     "Experience in writing clear, concise and comprehensive test plans and test cases",
  //     "Hands-on experience with both white box and black box testing",
  //     "Solid knowledge of SQL and scripting",
  //     "Experience working in an Agile/Scrum development process",
  //     "Experience with performance and/or security testing is a plus",
  //     "Experience with API Testing tools",
  //     "Experience with CI/CD tools is a plus",
  //     "In-depth understanding of Javascript programming language.",
  //     "Great team player and able to work efficiently with minimal supervision.",
  //   ],
  // },

  {
    JobTitle: "Software Tester",
    Duration: "Open till March, 2023",
    Worktype: "Full-Time",
    Responsibility: [
      "Reviewing software requirements and preparing test scenarios.",
      "Executing tests on software usability.",
      "Analyzing test results on database impacts, errors or bugs, and usability.",
      "Preparing reports on all aspects related to the software testing carried out and report to the design and product team.",
      "Participating in design reviews and providing input on requirements, product design, and potential problems.",
    ],
    Requirement: [
      "A bachelor's degree in computer science or any related field.",
      " Up-to-date knowledge of software test design and testing methodologies.",
      "Working knowledge of test techniques and compatibility with various software programs",
      "Working knowledge of programming.",
      " Excellent communication, critical thinking and problem-solving skills",
      "Good organizational skills and detail-oriented mindset.",
    ],

    Description:
      "We are looking for a results-driven software tester to be responsible for running tests on software usability. The software tester will conduct tests, analyze the results, and report observations to the product and design team. To be successful in this role, you should have a working knowledge of software and test design, the capability to run through tests, and the ability to analyze the results. Ultimately, you should be result-driven, have good communication skills, and an upto-date knowledge of software programming and software test design. ",
  },
  {
    JobTitle: "Product Marketing & Customer Experience Manager",
    Duration: "Open till April, 2023",
    Worktype: "Full-Time",
    Responsibility: [
      "Develop and execute product marketing strategies that support the company's overall objectives and drive revenue growth.",
      "Work closely with the product development team to understand the features and benefits of our products and create compelling messaging that resonates with our target audience.",
      "Create and manage marketing campaigns that increase brand awareness and drive customer engagement.",
      "Conduct market research to stay up-to-date on industry trends and competitor activity.",
      "Collaborate with cross-functional teams, including sales, product, and design, to ensure a seamless customer experience from initial contact through to purchase and post-purchase support.",
      "Define and track key performance metrics to evaluate the success of marketing campaigns and customer experience initiatives.",
      "Continuously identify opportunities for improvement and develop solutions to optimize the customer journey.",
      "Develop strategic partnership & sales with stakeholders across the tech ecosystem.",
      "Oversee business operations in relations to product and customers experience including Human resources.",
    ],
    Requirement: [
      "Bachelor's degree in marketing, business, or a related field.",
      "5+ years of experience in product marketing, customer experience, or a related field.",
      "Excellent communication and interpersonal skills",
      "Strong analytical and problem-solving skills",
      "Ability to work collaboratively with cross-functional teams",
      "Familiarity with marketing automation platforms, CRM systems, and other marketing tools.",
      "Experience managing and developing team members",
    ],

    Description:
      "We are seeking a highly motivated and results-driven Product Marketing, Customer Experience Partnership & sales Manager to join our team. The ideal candidate will be responsible for developing and executing product marketing strategies, as well as overseeing all customer interactions to ensure an exceptional customer experience.",
  },
];
