import React, { useState } from 'react'
import { ParentWorkComponent } from '../about/component'
import { SignupData } from '../../utils/data/data'

export const Bg =() =>{
    return(
        <div style={{backgroundImage:' url("/img/31021806_coin_bg280-removebg-preview 5.png")'}} className='hidden sm:block absolute h-[50px] w-[50px] bg-no-repeat bg-contain right-[40%] top-[10px]  z-[1000]'>

        </div>        
    )
}
export const BG =() =>{
    return(
        <div style={{backgroundImage:' url("/img/Naira.png")'}} className='hidden sm:block absolute h-[100px] w-[200px] bg-no-repeat bg-contain right-8rem md:right-[60px] top-[30px] md:top-[20px] z-[1000]'>

        </div>        
    )
}
export const Star =() =>{
    return(
        <div style={{backgroundImage:' url("/img/star.png")'}} className='hidden sm:block absolute h-[70px] w-[70px] bg-no-repeat bg-contain right-[50px] top-[10px]  z-[1000]'>

        </div>        
    )
}
export const Star2 =() =>{
    return(
        <div style={{backgroundImage:' url("/img/star.png")'}} className='hidden md:block absolute h-[70px] w-[70px] bg-no-repeat bg-contain left-0 xl:left-[30px] bottom-[50px]  z-[1000]'>

        </div>        
    )
}
const Arrow =() =>{
    return(
        <div style={{backgroundImage:' url("/img/graybrush.png")'}} className='sm:block absolute h-[100px] md:h-[150px] w-[200px] bg-no-repeat bg-contain right-0 md:right-[330px] top-[100px] z-[1000]'>

        </div>        
    )
}
export const SignUp = () => {
    const [isButton, setIsButton] = useState(true);
    const size = { width: 300, height: 300 };
    return (
      <>
        <div className="relative" id="signup">
          <BG />
          <Arrow />
          <ParentWorkComponent
            heading="Sign up as ..."
            children={SignupData}
            isButton={isButton}
            size={size}
          />
        </div>
      </>
    );
  };
