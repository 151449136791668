import React from 'react'
import BlogCard from './BlogCard'
import { BlogData } from '../../utils/data/data'


const BlogSection = () => {
  return (
    <div style={{backgroundColor:'rgba(248, 255, 244, 1)'}} className='w-full p-12 grid grid-col-1 sm:grid-cols-2 lg:grid-cols-3 gap-10'>
        {
            BlogData.map((blog, index)=>
                <BlogCard img={blog.img} title={blog.link} url={blog.linkUrl} date={blog.date} />
            )
        }
    </div>
  )
}

export default BlogSection
