import { KPIarticles } from "../../utils/data/data";
import { Container } from "../common/AltCard";
import { BodyContent } from "../common/styles";
import Benefit from "./Benefit";
import KPIHeader from "./KPIHeader";


const KPIContent = ({data}:any) => {
  const articles = KPIarticles;
  return (
    <BodyContent>
      <Container>
        {articles?.map((article, i) => (
          <KPIHeader {...article} key={i} />
        ))}
        <Benefit data={data} />
      </Container>
    </BodyContent>
  );
};

export default KPIContent;
