import { useEffect } from "react";
import ArticleSection from "../../components/common/ArticleSection";
import { businessOperators } from "../../utils/data/data";

const BusinessOperators = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

    return (
      <div className="pt-6">
        <ArticleSection {...businessOperators} />
      </div>
    );
  };
  
  export default BusinessOperators;