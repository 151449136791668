import React from 'react'
import GreenBackDrop from '../common/Green'
import { solutions } from '../../utils/data/data'
import Card from '../common/Card'
import { Container } from '../common/AltCard'

const Problems = () => {
  const textSize = {

    sm: 24,
    md: 28,
    lg: 50,
  };
  return (
    <GreenBackDrop bgCol='primary'>
      <Container>
          <div className='w-full grid gap-4 grid-cols-1 lg:grid-cols-3 py-6'>
              <h3 className='text-white font-extrabold text-5xl text-center lg:text-left mb-5 lg:mb-0 lg:mt-10 lg:text-6xl'>Problems We Are Solving</h3>
              {
                solutions.map((item, index)=>
                <div key={index} className='transform transition-all ease-in-out duration-500 hover:scale-105 '>
                  <Card
                      
                      width='100%'
                      height='250px'
                      title={item?.title}
                      desc={item?.text}
                      url=''
                      img={item.imgUrl}
                      w_img='70'
                      h_img='70'
                      
                  />                
                </div>

                ) 
              }
          </div>        
      </Container>


    </GreenBackDrop>
  )
}

export default Problems