import React from "react";
import { FC, ReactNode } from "react";


type props = {
  value?: string;
  placeholder?: string;
  name?: string;
  id?: string;
  accept?: string;
  type?: string;
  className?: string;
  required: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

const Input: FC<props> = ({
  value,
  type,
  id,
  onChange,
  placeholder,
  name,
  required,
  accept,
}) => {
  return (
    <>
      <input
        className="rounded-[5px] bg-[#fff] py-[0.3rem] px-[1em] text-[16px] text-[#000] outline-none shadow-none border border-[#c2c2c2] transition-all duration-[0.3s] ease-out relative h-[2.78rem] font-[500] w-full focus:border-2 "
        type={type}
        id={id}
        required={required}
        autoComplete="off"
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        accept={accept}
      />
    </>
  );
};

export default Input;
