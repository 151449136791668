import { useState } from "react";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";
import { IconContext } from "react-icons/lib";
import { TechnologyModel } from "../../utils/data/data";


type props = {
  data?: Array<TechnologyModel>;
};
const Accordion = ({ data }: props) => {
  // console.log(data, "Arraydata");
  const [clicked, setClicked] = useState(0);

  const toggle = (index: number) => {
    if (clicked === index) {
      return setClicked(-1);
    }
    setClicked(index);
    console.log(clicked);
  };
  return (
    <IconContext.Provider value={{ color: "#3D4E4B" }}>
      <section className="flex flex-col items-center justify-center ">
        <div className="w-full">
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  clicked === index
                    ? "border-b-4 border-primary border-opacity-25"
                    : "border-b-2 border-primary border-opacity-25"
                }
              >
                <div className="flex items-center justify-between gap-3 py-5" onClick={() => toggle(index)} key={index}>
                  <h4 className="lg:text-lg text-base font-bold text-blak cursor-pointer w-10/12 md:w-full">
                    {item?.text}
                  </h4>
                  {clicked === index ? <FiMinusCircle /> : <FiPlusCircle />}
                </div>
                {clicked === index ? (
                  <p className="pb-4 text-sm text-body ease-in-out duration-500">
                    {item?.description}
                  </p>
                ) : null}
              </div>
            );
          })}
        </div>
      </section>
    </IconContext.Provider>
  );
};

export default Accordion;
