
import EndUserContent from '../../components/enduser/EndUserContent'
import {PageBanner} from '../../components/common/PageBanner'
import { useState } from 'react'
import { Container } from '../../components/common/AltCard'

const EndUser = () => {
  const [tab, setTab] = useState('users')
  return (
    <div className='pt-16'>
        <PageBanner text={'Pakam Mobile Application End User Lincense Aggrement'}>
        <button className={`px-[0.25rem] ${tab==='users'? 'border-b border-[2px] text-white':'text-white opacity-50'}`}  onClick={()=>setTab('users')}>For End User Lincense Aggrement</button>
        </PageBanner> 
        
        <Container>
        {tab === 'users'?<EndUserContent/>:''}        
    </Container>
    </div>

  )
}

export default EndUser