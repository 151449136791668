import React from 'react'

const Loader = () => {
  return (
    <>
      <div className='w-full h-full overflow-hidden flex justify-center items-center z-[21]'>
        <div className='w-16 h-16 border-4 border-blue-400 border-solid rounded-full animate-spin border-t-transparent'>

        </div>
      </div>
    </>
  )
}

export default Loader
