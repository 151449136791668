import { useEffect, useState } from "react";
import { osName } from "react-device-detect";
import * as platform from "platform";
import { EarnWasteContentModel } from "../../utils/data/data";
import { BodyParagraph, Br, CardWrapper, ContentContainer, ImageContent, ProcessHeader, Section, Span, WorkHeader } from "./styles";
import Button from "./Button";



const NeoCard = ({
  imgSrc,
  imgAlt,
  header1,
  header2,
  sub_text,
  reverse,
  soonchecker,
}: EarnWasteContentModel) => {
  const [device, setDevice] = useState("");

  useEffect(() => {
    setDevice(platform?.os?.family ?? "unknown");
  }, [device]);  
    
  return (
    <CardWrapper reverse={reverse ? true : false}>
      <ImageContent>
        <img
          src={imgSrc}
          alt={imgAlt}
          className="object-contain w-[400px] h-[350px] md:w-[600px] md:h-[450px]"
        />
      </ImageContent>

      <ContentContainer>
        <ProcessHeader>{header1}</ProcessHeader>
        <WorkHeader>{header2}</WorkHeader>

        {sub_text?.map((item, index) => {
          return (
            <Section key={index}>
              <Span text={`${index + 1}.`}/>
              <BodyParagraph reverse={reverse ? true : false} text={item}></BodyParagraph>
            </Section>
          );
        })}

        <Br/>

        {soonchecker ? (
          <Button
            content="Coming Soon"
            icons={false}
            weight={false}
            primary={true}
            white={true}
            width={true}
            className=""
          />
        ) : device === "Windows" ? (
          <a
            href="https://play.google.com/store/apps/details?id=com.pakamcustomer"
            target="_blank"
          >
            <Button
              content="Download Now!"
              icons={false}
              weight={false}
              primary={true}
              white={true}
              width={true}
              className="text-white"
            />
          </a>
        ) : device === "macOS" ? (
          <a
            href="https://apps.apple.com/ng/app/pakam-household-recycling-app/id1539296957"
            target="_blank"
          >
            <Button
              content="Download Now!"
              icons={false}
              weight={false}
              primary={true}
              white={true}
              width={true}
              className=""
            />
          </a>
        ) : osName === "iOS" ? (
          <a
            href="https://apps.apple.com/ng/app/pakam-household-recycling-app/id1539296957"
            target="_blank"
          >
            <Button
              content="Download Now!"
              icons={false}
              weight={false}
              primary={true}
              white={true}
              width={true}
              className=""
            />
          </a>
        ) : (
          <a
            href="https://play.google.com/store/apps/details?id=com.pakamcustomer"
            target="_blank"
          >
            <Button
              content="Download Now!"
              icons={false}
              weight={false}
              primary={true}
              white={true}
              width={true}
              className=""
            />
          </a>
        )}
      </ContentContainer>
    </CardWrapper>
  );
};

export default NeoCard;

// {OSNAME}
