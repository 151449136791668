import React from 'react'
import { Container } from '../../components/common/AltCard';
import Accordion from '../../components/common/Accordion';
import { faqs } from '../../utils/data/data';
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { faqRequest } from '../../hooks/ApiRequest';
import Input from '../../components/common/Input';
import Button from '../../components/common/Button';
import FaqForm from '../../components/faq/FaqForm';

const FAQs = () => {


    return (
        <div className='w-full pt-20'>
          <div style={{backgroundColor:'rgba(0, 87, 0, 1)', color:'rgba(255, 255, 255, 1)'}} className='w-full h-[280px] lg:h-[350px] flex justify-center items-center'>
            <div>
              <h2 className='font-[800] font-[Raleway] text-[48px]  text-center'>Frequently Asked Questions</h2>       
            </div>
          </div>
          <div className='bg-secondary py-6 lg:py-20 '>
            <Container>
              {/* <FaqHeader>
                <FaqHeaderH1>FAQs</FaqHeaderH1>
    
                <FaqHeaderP>
                  You have got any questions? we’ve got you covered!
                </FaqHeaderP>
              </FaqHeader> */}
              <div className='w-full lg:w-4/5 mx-auto mt-8 lg:mt-12 '>
                <Accordion data={faqs} />
              </div>
            </Container>
          </div>
    
          <FaqForm/>

        </div>
      );

}

export default FAQs


