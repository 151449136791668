import React, { useState } from 'react'
import NoOpening from '../../components/career/NoOpening';
import CareerPage from '../../components/career/CareerPage';

const Career = () => {
    const [isJobOpen, setIsJobOpen] = useState(false);
  return (
    <>
       {
        !isJobOpen ?
            <NoOpening/>
            :
            <CareerPage/>
       } 
    </>
  )     
}

export default Career