import Lottie from 'lottie-react';
import React from 'react'
import { values } from '../../utils/data/data';
import { chunk } from '../../utils';
import { Container } from '../common/AltCard';

const Value = () => {
  return (
    <section className='bg-value-pattern bg-cover bg-no-repeat min-h-full w-full py-[4rem] lg:py-[5.2rem]'>
      <Container>
        <h3 className='font-extrabold text-white text-center text-5xl lg:text-6xl'>Our Core Values.</h3>
        <div className='flex flex-col gap-4 mt-8'>
          {chunk(values.slice(0, 6), 3)?.map((value) => {
            return (
              <div className='flex flex-col lg:flex-row justify-center items-stretch gap-4'>
                {value.map((el, id) => {
                  return (
                    <div
                      key={id}
                      className="rounded-lg shadow-md p-4 bg-white flex flex-col items-center justify-center lg:w-1/5 max-h-full  space-y-3 hover:-translate-y-1.5 hover:ease-in-out duration-300"
                    >
                      <Lottie
                        animationData={el.imgUrl}
                        loop={true}
                        className="lg:w-3/4 w-32 h-32 lg:h-4/5 "
                      />
                      <p>{el.text}</p>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Container>
    </section>
  )
}

export default Value