import { useEffect } from "react";
import ArticleSection from "../../components/common/ArticleSection";
import { citizens } from "../../utils/data/data";


const Citizens = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
  return (
    <>
      <ArticleSection {...citizens} />
    </>
  );
};

export default Citizens;
