import React, { useState, useEffect, useRef } from "react";

import VanillaTilt from "vanilla-tilt";
// import GP from "/public/img/ContactPointer.png";
import GP from "../../../assets/Group 626021.png";
import LG from "../../../assets/LGImage.png";
import Forms from "./Form";



function Contacts() {
  const [width, setWidth] = useState(1200);

  const tilt = useRef(null);
  useEffect(() => {
    return VanillaTilt.init(tilt.current!, {
      reverse: true,
    });
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="pb-20 sm:-mt-10 md:pt-20 bg-secondary" id="resume">
      <div className="flex flex-wrap items-center lg:flex-row-reverse justify-between flex-col">
        <div className=" w-full lg:basis-[48%]">
          <div>
            <Forms />
          </div>
        </div>
        <div  className=" w-full flex items-center justify-center lg:basis-[52%]">
          <div>
            <div className="max-w-[555px]" ref={tilt}>
              {width > 1000 ? (
                <img
                  src={LG}
                  className="object-contain"
                  // layout="responsive"
                  // width={300}
                />
              ) : (
                <img src={GP} className="object-cover" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
