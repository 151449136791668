import React from 'react'
import { Container } from '../common/AltCard'
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/pagination";
// import { Autoplay, Pagination } from 'swiper/modules';
import { Autoplay, Pagination } from "swiper";
import { TypeAnimation } from 'react-type-animation';


const Testimonials2 = () => {

  return (
    <Container>
        <div className='flex flex-col lg:flex-row my-[4rem] justify-center lg:pl-[6rem]'>
            <div className='w-full lg:w-1/3'>
                <p className='text-center lg:text-left font-int font-[500] text-[16px] text-[#7A828F] md:my-4'>WHY PAKAM</p>
                {/* <h1 className='text-center lg:text-left text-[#35393F] font-Rob font-[800] text-[28px] md:text-[48px] leading-[45px]'>Reliable</h1> */}
                <div className='flex w-full justify-center lg:justify-normal'>
                    <TypeAnimation
                        sequence={[
                        "Reliable",
                        1000,
                        "Convenience",
                        1500,
                        "Ease",
                        2000, // Waits 2s
                        "Innovation",
                        2500,
                        "Transparency",
                        3000,
                        "Accessibility",
                        3500,
                        "Profitability",
                        4000,
                        // () => {
                        //     console.log(" typing!");
                        // },
                        ]}
                        wrapper="span"
                        cursor={true}
                        repeat={Infinity}
                        className="text-center w-full lg:text-left text-[#35393F] font-Rob font-[800] text-[28px] md:text-[48px] leading-[45px]"
                    />                    
                </div>

            </div>
            <div className='hidden w-2/3 lg:flex gap-3'>
                <div className=' flex flex-col gap-3 w-[440px]'>
                    <div className='rounded-lg border border-[#8992A1] shadow-lg bg-[#DCFCE7] p-3 w-full h-[180px]'>
                        <div className='flex gap-2 items-center'>
                            <img className='w-[20px] h-[20px]' src="/svg/tesIcon.svg" alt="test-icon" />
                            <p className='font-[700] text-[#8992A1] font-Rob text-[16px]'>Abolanle Kasumu</p>
                        </div>
                        
                        <p className=' text-[16px] mt-4 md:text-[14px] text-[#0F172A] font-Rob font-[400]'>I want to say a big thank you for your response to picking up the plastic waste at my location, Egbeda, Alimosho and for also crediting my wallet account. Together, we would rid the state of plastic pollution for a healthier, cleaner and safer Lagos and its environment.</p>
                    </div>
                    <div className='w-full flex justify-end'>
                        <div className='rounded-lg border border-[#8992A1] shadow-lg bg-[#FAFCEC] p-3 w-1/2  h-[180px]'>
                            <div className='flex gap-2 items-center'>
                                <img className='w-[20px] h-[20px]' src="/svg/tesIcon.svg" alt="test-icon" />
                                <p className='font-[700] text-[#8992A1] font-Rob text-[16px]'>Oyindamola Kayode</p>
                            </div>
                            
                            <p className=' text-[16px] mt-4 md:text-[14px] text-[#0F172A] font-Rob font-[400]'>Pakam has helped me make a positive impact on the environment while earning rewards. It's a great initiative!</p>
                        </div>                        
                    </div>

                </div>
                <div className='w-1/3 flex flex-col gap-3'>
                    <div className='rounded-lg border border-[#8992A1] shadow-lg bg-[#FEF6E8] p-3 h-[200px] w-[220px]'>
                        <div className='flex gap-2 items-center'>
                            <img className='w-[20px] h-[20px]' src="/svg/tesIcon.svg" alt="test-icon" />
                            <p className='font-[700] text-[#8992A1] font-Rob text-[16px]'>Chidima Eze</p>
                        </div>
                        
                        <p className=' text-[16px] mt-4 md:text-[14px] text-[#0F172A] font-Rob font-[400]'>I do admire the fact that you are following up with my opinion and the way and manner with which you’re going about it. Customer services is 10/10.</p>
                    </div>
                    <div className='rounded-lg border border-[#8992A1] shadow-lg bg-[#DCFCE7] p-3 h-[200px] w-[220px]'>
                        <div className='flex gap-2 items-center'>
                            <img className='w-[20px] h-[20px]' src="/svg/tesIcon.svg" alt="test-icon" />
                            <p className='font-[700] text-[#8992A1] font-Rob text-[16px]'>Mahmudat Saheed</p>
                        </div>
                        
                        <p className=' text-[16px] mt-4 md:text-[14px] text-[#0F172A] font-Rob font-[400]'>So far, it's been amazing. Regular Pickups, easy scheduling and polite vendors. It’s been an overall positive experience and I'm sure it can even be much better.</p>
                    </div>

                </div>

            </div>

            <div className='lg:hidden mt-6'>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                breakpoints={{
                    375: {
                        slidesPerView: 1,
                    },
                    425: {
                        slidesPerView: 2,
                    }
                }}
                autoplay={{
                  delay: 4500,
                  disableOnInteraction: true,
                }}
                // pagination={{
                //   clickable: true,
                // }}
                // navigation={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >
                  <SwiperSlide>
                    <div className='rounded-lg border border-[#8992A1] shadow-lg bg-[#DCFCE7] p-3 w-full h-[200px]'>
                        <div className='flex gap-2 items-center'>
                            <img className='w-[20px] h-[20px]' src="/svg/tesIcon.svg" alt="test-icon" />
                            <p className='font-[700] text-[#8992A1] font-Rob text-[16px]'>Abolanle Kasumu</p>
                        </div>
                        
                        <p className=' text-[16px] mt-4 md:text-[14px] text-[#0F172A] font-Rob font-[400]'>I want to say a big thank you for your response to picking up the plastic waste at my location, Egbeda, Alimosho and for also crediting my wallet account. Together, we would rid the state of plastic pollution for a healthier, cleaner and safer Lagos and its environment.</p>
                    </div>
                </SwiperSlide>
                  <SwiperSlide>
                  <div className='rounded-lg border border-[#8992A1] shadow-lg bg-[#FAFCEC] p-3 w-full  h-[180px]'>
                            <div className='flex gap-2 items-center'>
                                <img className='w-[20px] h-[20px]' src="/svg/tesIcon.svg" alt="test-icon" />
                                <p className='font-[700] text-[#8992A1] font-Rob text-[16px]'>Oyindamola Kayode</p>
                            </div>
                            
                            <p className=' text-[16px] mt-4 md:text-[14px] text-[#0F172A] font-Rob font-[400]'>Pakam has helped me make a positive impact on the environment while earning rewards. It's a great initiative!</p>
                        </div> 
                </SwiperSlide>
                  <SwiperSlide>
                  <div className='rounded-lg border border-[#8992A1] shadow-lg bg-[#FEF6E8] p-3 h-[180px] w-full'>
                        <div className='flex gap-2 items-center'>
                            <img className='w-[20px] h-[20px]' src="/svg/tesIcon.svg" alt="test-icon" />
                            <p className='font-[700] text-[#8992A1] font-Rob text-[16px]'>Chidima Eze</p>
                        </div>
                        
                        <p className=' text-[16px] mt-4 md:text-[14px] text-[#0F172A] font-Rob font-[400]'>I do admire the fact that you are following up with my opinion and the way and manner with which you’re going about it. Customer services is 10/10.</p>
                    </div>
                </SwiperSlide>
                  <SwiperSlide>
                  <div className='rounded-lg border border-[#8992A1] shadow-lg bg-[#DCFCE7] p-3 h-[180px] w-full'>
                        <div className='flex gap-2 items-center'>
                            <img className='w-[20px] h-[20px]' src="/svg/tesIcon.svg" alt="test-icon" />
                            <p className='font-[700] text-[#8992A1] font-Rob text-[16px]'>Mahmudat Saheed</p>
                        </div>
                        
                        <p className=' text-[16px] mt-4 md:text-[14px] text-[#0F172A] font-Rob font-[400]'>So far, it's been amazing. Regular Pickups, easy scheduling and polite vendors. It’s been an overall positive experience and I'm sure it can even be much better.</p>
                    </div>
                </SwiperSlide>
                

              </Swiper>

            </div>
        </div>        
    </Container>

  )
}

export default Testimonials2