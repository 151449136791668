import React from "react";

interface divProp{
    children: React.ReactNode
}

interface textProp{
    text: string
}

interface divProp2{
    children: React.ReactNode
    reverse?: boolean
    top?:boolean
}
interface divProp3{
    children: React.ReactNode
    top?:boolean
}

interface textProp2{
    text: string
    reverse: boolean
}

export const OverallContainer: React.FC<divProp> = ({children})=>{
    return(
        <div className="bg-primary pb-[60px] lg:pb-[70px] min-h-[100vh] flex items-center justify-center">
            {children}
        </div>
    )
} 
export const Br = ()=>{
    return(
        <div className="block mt-[20px]">
            
        </div>
    )
} 
export const ImageContent: React.FC<divProp> = ({children})=>{
    return(
        <div className=" w-full md:w-[47%]">
            {children}
        </div>
    )
} 
export const BodyContent: React.FC<divProp> = ({children})=>{
    return(
        <div className=" bg-secondary">
            {children}
        </div>
    )
} 
export const FeatureSection: React.FC<divProp> = ({children})=>{
    return(
        <div className=" grid grid-cols-1 md:grid-cols-2   lg:grid-cols-3  xl:grid-cols-4 gap-7 lg:gap-5 pb-20">
            {children}
        </div>
    )
} 
export const DescSection: React.FC<divProp> = ({children})=>{
    return(
        <div className="text-center flex flex-col items-center justify-center">
            {children}
        </div>
    )
} 
export const ContentContainer: React.FC<divProp> = ({children})=>{
    return(
        <div className=" w-full md:w-[47%] pt-10">
            {children}
        </div>
    )
} 
export const ProcessHeader: React.FC<divProp> = ({children})=>{
    return(
        <div className=" text-base font-medium lg:text-[20px]  md:text-2xl md:text-left ">
            {children}
        </div>
    )
} 
export const WorkHeader: React.FC<divProp> = ({children})=>{
    return(
        <div className=" text-4xl  font-extrabold lg:text-5xl lg:font-extrabold text-primary pt-1 pb-7 md:font-bold md:text-left">
            {children}
        </div>
    )
} 
export const Section: React.FC<divProp> = ({children})=>{
    return(
        <div className="flex gap-5 pb-7 lg:pb-6">
            {children}
        </div>
    )
} 
export const BackgroundContainer: React.FC<divProp> = ({children})=>{
    return(
        <div className="bg-secondary pb-12 md:pb-20">
            {children}
        </div>
    )
} 

export const Heading = ({text}:textProp)=>{
    return(
        <h1 className="text-left  lg:text-center text-xl sm:text-4xl text-white pt-10 font-extrabold font-Raleway lg:text-4xl">
            {text}
        </h1>
    )
} 
export const KPIHeading = ({text}:textProp)=>{
    return(
        <h1 className="text-primary text-4xl  font-extrabold leading-snug lg:text-5xl md:text-4xl  ">
            {text}
        </h1>
    )
} 
export const Span = ({text}:textProp)=>{
    return(
        <span className="text-sm font-medium">
            {text}
        </span>
    )
} 



export const Flex:React.FC<divProp2> = ({children, reverse, top}) =>{
    return(
        <div className={`flex flex-col lg:flex-row lg:w-full gap-10 items-center justify-between mt-0 ${reverse ? 'flex-row-reverse' : 'row '} ${top ? 'pt-[20px] md:pt-[10px] lg:pt-[70px] ': ""}`}>
            {children}
        </div>        
    )

} 
export const CardWrapper:React.FC<divProp2> = ({children, reverse}) =>{
    return(
        <div className={` md:flex justify-between items-center pt-16 pb-0  md:pb-10 ${reverse ? 'flex-row-reverse' : 'row '}`}>
            {children}
        </div>        
    )

} 

export const Paragraph = ({reverse, text}:textProp2) =>{
    return(
        <p className={`text-white pt-10 lg:pt-0 xl:pt-10 pb-10 lg:pb-20  lg:text-base lg:leading-[40px] text-sm leading-9 font-light ${reverse? 'mt-[30px]': ''}`}>
            {text}
        </p>
    )
} 
export const BodyParagraph = ({reverse, text}:textProp2) =>{
    return(
        <p className={`text-body lg:pt-0   lg:text-base text-sm leading-4 font-light ${reverse? 'mt-0': ''}`}>
            {text}
        </p>
    )
} 
export const KPIParagraph = ({ text}:textProp2) =>{
    return(
        <p className={`text-body pt-5 pb-5  md:mt-0 md:mb-5  lg:w-4/5 lg:mt-6 w-full lg:pt-0 lg:text-[17px] text-sm leading-6 md:leading-loose`}>
            {text}
        </p>
    )
} 

export const ImageContains:React.FC<divProp3> = ({children, top}) =>{
    return(
        <div className={`${!top? 'mt-[-80px]' : ''}`}>
            {children}
        </div>        
    )

 }

export const ImageContainer: React.FC<divProp> = ({children}) =>{
    return (
        <div className="m-auto mt-[-60px] pt-[10px] pb-0 lg:pt-0 lg:my-[30px]">
            {children}
        </div>
    )
}
