import React, { useEffect, useState } from "react";
import { Container } from "../../components/common/AltCard";
import CustomTab from "../../components/common/CustomTab";
import SupportRecycler from "../../components/support/recycler";
import SupportHousehold from "../../components/support/household";


type Props = {};

const Support = (props: Props) => {
  return (
    <div className="pt-20">
      <Container>
        <section className="flex items-center flex-col pt-12">
          <div className="text-primary text-2xl lg:text-4xl pb-10 font-extrabold  text-center">
            What will you like to know?
          </div>
          <div>
            <CustomTab
              elements={[
                {
                  tabTitle: "Household User",
                  tabBody: <SupportHousehold />,
                  path: "household",
                },
                {
                  tabTitle: "Recycler",
                  tabBody: <SupportRecycler />,
                  path: "recycler",
                },
              ]}
              basePath="/support"
            />            
          </div>

        </section>
      </Container>      
    </div>

  );
};

export default Support;
