import React, {ReactNode} from 'react'
import Marquee from 'react-fast-marquee';
import { wastedata } from '../../utils/data/data';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";

const WasteMaquee = () => {
  return (
    <div className=' w-full py-4 md:py-8'>
        {/* <Marquee
          gradient={true}
        //   gradientColor={[248, 251, 251]}
          direction= {'left'}
          // speed={speed}
        >
          <div
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-8"
            className='flex justify-between w-full  md:gap-[5.7rem] items-center'
          >
            {wastedata.map((el, i) => (
                <div style={{backgroundColor:'rgba(227, 255, 227, 0.3)'}} key={i} className='flex justify-center items-center px-4 py-1 gap-4'>
                    <p>{el.title}</p>
                    <img
                        src={el.icon}
                        className={`w-[40px] h-[40px]`}
                    />                    
                </div>

            ))}
          </div>
        </Marquee> */}



        <Swiper
                spaceBetween={10}
                // centeredSlides={true}
                
                loop={true}
                loopedSlidesLimit={false}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: true,
                }}
                breakpoints={{
                  375: {
                      slidesPerView: 1,
                  },
                  425: {
                      slidesPerView: 2,
                  },
                  768: {
                      slidesPerView: 5,
                  },
                  1024: {
                      slidesPerView: 6,
                  },
              }}
                // pagination={{
                //   clickable: true,
                // }}
                // navigation={true}
                modules={[Autoplay, Pagination]}
                className="mySwiper"
              >

                {wastedata.map((el, i) => (
                  <SwiperSlide key={i}>
                            <div style={{backgroundColor:'rgba(227, 255, 227, 0.3)'}} className='flex justify-center items-center px-4 py-1 gap-4'>
                                    <p>{el.title}</p>
                                    <img
                                        src={el.icon}
                                        className={`w-[40px] h-[40px]`}
                                    />                    
                                </div>
                  </SwiperSlide>
                            ))
                  }



                

              </Swiper>
      
    </div>
  )
}

export default WasteMaquee
