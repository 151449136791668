import React, { useEffect } from 'react'
import Glance from '../../components/home/Glance'
import Support from '../../components/home/Support'
import Members from '../../components/home/Members'
import Hero from '../../components/home/Hero'
import WhoWeServe from '../../components/home/WhoWeServe'
import axios from 'axios'
import Recycletowork from '../../components/home/Recycletowork'
import StatBanner from '../../components/home/StatBanner'
import Calculate from '../../components/home/Calculate'
import Effortless from '../../components/home/Effortless'
import MarketPlace from '../../components/home/MarketPlace'
import RecycleEarn from '../../components/home/RecycleEarn'
import Become from '../../components/home/Become'
import How from '../../components/home/How'
import Testimonials2 from '../../components/home/Testimonials2'
import WasteMaquee from '../../components/home/WasteMarquee'


const Home = () => {

  useEffect(()=>{
    const stagingEnpoint = 'https://stagingapi.pakam.ng/api/facebook/notify'
    const handleFBStaging = async () =>{
      try {
        const res = await axios.post(stagingEnpoint, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error)
      }
    }

    handleFBStaging()
  }, [])

  return (
    <div className='w-full'>
      <Hero/>
      {/* <Glance/> */}
      <MarketPlace/>
      
      <Support/>
      <WhoWeServe/>
      <Effortless/>
      <WasteMaquee/>
      <How/>
      <RecycleEarn/>
      <Become/>
      
      <Calculate/>
      <Testimonials2/>
      <StatBanner/>
      <Members/>
      
      <Recycletowork/>

    </div>
  )
}

export default Home
