import { useEffect } from "react";
import ArticleSection from "../../components/common/ArticleSection";
import { agencies } from "../../utils/data/data";

const Agencies = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);
    return (
      <>
        <ArticleSection {...agencies} />
      </>
    );
  };
  
  export default Agencies;