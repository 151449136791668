import React from "react";

interface divProp{
    children: React.ReactNode
}

interface textProp{
    text: string
}

interface divProp2{
    children: React.ReactNode
    reverse?: boolean
    top?:boolean
}
interface divProp3{
    children: React.ReactNode
    top?:boolean
}

interface textProp2{
    text: string
    reverse: boolean
}

export const CardWrapper: React.FC<divProp> = ({children})=>{
    return(
        <div>
            {children}
        </div>
    )
} 
export const Content = ({ text}:textProp) =>{
    return(
        <p className={`text-xs leading-7 pt-4 pb-3 text-body font-medium`}>
            {text}
        </p>
    )
} 
export const Name = ({ text}:textProp) =>{
    return(
        <p className={`text-base font-bold leading-loose`}>
            {text}
        </p>
    )
} 
export const Role = ({ text}:textProp) =>{
    return(
        <p className={`font-normal font-medium text-sm leading-snug text-primary italic`}>
            {text}
        </p>
    )
} 

export const Wrapper: React.FC<divProp> = ({children})=>{
    return(
        <div className="rounded-[10px] min-h-[340px] w-[100%] leading-[24px] bg-white py-[20px] px-[30px]">
            {children}
        </div>
    )
} 
