/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";

import Modal from "react-modal";
import { IoClose } from "react-icons/io5";
import { Container } from "../common/AltCard";
import { SDGImageArray, SDGImageModalValue } from "../../utils/data/data";

interface divProps{
    children: React.ReactNode
}
interface imgProps{
    className?: string,
    source:string,
    alt:string
}

const SDGContainer:React.FC<divProps> = ({children})=>{
    return(
        <section className="w-full bg-secondary py-[4rem] lg:py-[5.2rem]">
            {children}
        </section>
    )
}
const SDGImageContainer:React.FC<divProps> = ({children})=>{
    return(
        <section className="flex justify-center items-start pb-4 mx-auto mt-12 w-auto min-h-[321px]">
            {children}
        </section>
    )
}
const SDGHeader:React.FC<divProps> = ({children})=>{
    return(
        <div className="lg:max-w-[500px] w-full mx-auto mb-5">
            {children}
        </div>
    )
}
const SDGImageWrapper:React.FC<divProps> = ({children})=>{
    return(
        <div className={`mr-21 space-x-2 space-y-2`}>
            {children}
        </div>
    )
}
const Modalbody:React.FC<divProps> = ({children})=>{
    return(
        <div className="bg-white pt-[5px] px-[20px] pb-1 max-w-[500px] mx-auto min-h-[185px]  rounded-[15px]">
            {children}
        </div>
    )
}

const SDGImage = ({ className, source, alt }:imgProps) => (
    <img
      className={`cursor-pointer ${className} ${className === 'gif' ? 'w-116 h-116 lg:mr-4 mx-auto' : 'w-126 h-126'} ${
        className === 'gif' ? 'lg:mr-4 mx-auto' : ''
      } ${className === 'gif' ? 'w-116 h-116' : ''} ${className === 'gif' ? 'lg:mr-4 mx-auto' : ''}`}

      src={source}
      alt={alt}
    />
  );
  


// const DescText = styled.p`
//   ${tw`text-sm text-blak font-medium text-center `}
// `;

// const SDGImageWrapper = styled.div`
//   ${tw`mr-[21px]`};
//   &:nth-child(even) {
//     ${tw`self-end`};
//   }
// `;


// Modal.setAppElement("#yourAppElement");

const SDG = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [{ modalalt, url, title, subtitle }, setInfo] = useState({
    modalalt: "",
    url: "",
    title: "",
    subtitle: "",
  });

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  type IValue = keyof typeof SDGImageModalValue;
  const handleOpenModal = (value: IValue) => {
    if (!SDGImageModalValue[value]) return;
    setInfo({ modalalt: "", url: "", title: "", subtitle: "" });
    setTimeout(() => {
      setInfo(SDGImageModalValue[value]);
    }, 100);
    setTimeout(openModal, 101);
  };

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(38,38,38, .75)",
      zIndex: "30",
    },
    content: {
      background: "transparent",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "1px solid #FFFFFF",
      borderRadius: "20px",
      filter: "drop-shadow(0px 2.05834px 6.04394px rgba(215, 228, 249, 0.5))",
      width: "auto",
      height: "auto",
      padding: "12px",
    },
  };
  return (
    <>
      <div className="home_width_control bg-[#FAFAFA]">
        <SDGContainer>
          <Container>
            <SDGHeader>
              <img
                src="/img/SG.png"
                alt="Sustainable development goals"
                height={164}
                width={771}
                className="mx-auto w-full"
              />

              <p className="text-sm text-blak font-medium text-center ">
                We adhere to the economic sustainability standards, and our
                sustainable development goals focus on the following listed
                goals.
              </p>
            </SDGHeader>
            <SDGImageContainer>
              {SDGImageArray.length > 0 &&
                SDGImageArray.map((article, i) => {
                  return (
                    <SDGImageWrapper key={i}>
                      {article.map(({ image, alt }, idx) => (
                        <div key={idx} onMouseEnter={() => {
                                handleOpenModal(alt as IValue);
                                setTimeout(closeModal, 3000);
                            }}>
                            <SDGImage
                            source={image}
                            alt={alt}
                            
                           
                            />                            
                        </div>

                      ))}
                    </SDGImageWrapper>
                  );
                })}
            </SDGImageContainer>
          </Container>
        </SDGContainer>
      </div>
      {/* @ts-ignore */}

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}

        style={customStyles}
        contentLabel="SDG Modal"
      > 
        <div onMouseLeave={closeModal}>
            <Modalbody >
            <button className="block ml-auto" onClick={closeModal}>
                <div className="rounded-full bg-gray-200 w-[30px] h-[30px] flex justify-center items-center ">
                <IoClose className="text-black" />
                </div>
            </button>

            <div className="lg:flex justify-between items-start">
                <img className="gif cursor-pointer w-[116px] h-[116px] lg:mr-4 mx-auto" src={url} alt={modalalt} />
                <div>
                <p className="mb-4 font-bold text-[18px] leading-[22px] text-[#222d33]">{title}</p>
                <p className=" font-[500] text-[16px] leading-[24px] text-[#464f54]">{subtitle}</p>
                </div>
            </div>
            </Modalbody>
        </div>

      </Modal>
    </>
  );
};

export default SDG;
