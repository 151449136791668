import React from "react";

export const HeroRight = () => {
  return (
    <div>
      <img
        src="/img/Group.png"
        width="800px"
        height="550px"
        alt="hero"
        className="object-contain"
      />
    </div>
  );
};
