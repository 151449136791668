
import React from "react";
import { Link } from "react-router-dom";
import { Container } from "../../common/AltCard";
import { householdSupport } from "../../../utils/data/data";


type Props = {};

const SupportHousehold = (props: Props) => {
  return (
    <Container>
      <div className="grid  xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2  grid-cols-1 lg:gap-8 gap-4 items-center justify-center mx-auto my-8">
        {householdSupport.map((el, i) => {
          return (
            <Link to={`/support/details?type=household&id=${el.id}`} key={i}>
              <div className="flex flex-col whitespace-normal space-y-2 hover:cursor-pointer ">
                <img src={el.imgUrl} alt="img" className="h-2/5 w-40" />
                <h6 className="font-bold text-primary text-lg h-14 pb-20 ">{el.title}</h6>
                <h6 className="text-sm text-dark-gray">{el.subtitle}</h6>
              </div>
            </Link>
          );
        })}
      </div>
    </Container>
  );
};

export default SupportHousehold;
