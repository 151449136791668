import React from "react";
import { PartnershipAnimation } from "./PartnerAnimation";
import { HeroLeft } from "./HeroLeft";
import { HeroRight } from "./HeroRight";

export const Partners = () => {
  return (
    <div className="flex-col md:flex-row flex items-center justify-between pt-20 lg:pt-10">
      <div className="basis-full mt-8 md:mt-0 md:basis-2/5">
        <HeroLeft />
      </div>
      <div className=" basis-full md:basis-3/5">
        <HeroRight />
      </div>
    </div>
  );
};
