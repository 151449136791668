import { support } from "../../utils/data/data";
import BannerSlide from "../common/BannerSlide";


const Support = () => {
  return (
    <BannerSlide
    // speed={-2}
      title="WE ARE SUPPORTED BY."
      imgUrl={support}
      direction="right"
      classname="object-contain !ml-2 lg:!ml-8 "
    />
  );
};

export default Support;
