import React from "react";
import { DescSection, Heading, KPIHeading, KPIParagraph, Paragraph } from "../common/styles";

const KPIHeader = ({ heading = "", text = "" }) => {
  return (
    <DescSection>
      <KPIHeading text={heading}/>
      <KPIParagraph text={text} reverse/>
    </DescSection>
  );
};

export default KPIHeader;
