import React from 'react'
import { CardContainer, GridStyle } from '../common/AltCard';
import Button from '../common/Button';
import { BG, Bg, Star, Star2 } from '../partner/SignUp';
import { Link, animateScroll as scroll } from "react-scroll";

interface HeaderTextProp{
  text: string;
  color: string;
  align: string;
  // size: string
  size: {
    // xs: number;
    sm: number;
    md: number;
    lg: number;
  };
}
interface DoCardProp{
  img: string;
  desc: string | undefined
}
interface TitleProp{
  desc: string;
}

export const Title = ({desc}: TitleProp) => {
  return (
    <p className='text-2xl md:text-4xl text-white font-bold'>{desc}</p>
  )
}
// export const HeaderText = ({text, color, align, size}: HeaderTextProp) => {
//   return (
//     <h1 style={{color: `${color}`}} className={`font-[Raleway] font-[800] text-${align}  sm:text-[${size.sm}px] md:text-[${size.md}px] lg:text-[${size.lg}px]`}>{text}</h1>
//   )
// }
export const DescText = ({text, color, align, size}: HeaderTextProp) => {
  return (
    <h1 style={{color: `${color}`}} className={`font-[Raleway] font-[500] text-[${size}px] text-${align}`}>{text}</h1>
  )
}

export const DoCard = ({img, desc}: DoCardProp) =>{
  return(
    <div style={{backgroundColor: 'rgba(255, 255, 255, 1)'}} className='flex flex-col items-center justify-center space-y-6  hover:-translate-y-1.5 hover:ease-in-out duration-300 px-2 lg:px-8 py-8 shadow-sm rounded-lg'>
      <img className='w-[57px] h-[57px]' src={img} alt="card icon" />
      <p className='text-xs md:text-sm text-center'>{desc}</p>
    </div>
  )
}

export const ParentWorkComponent = ({
  children,
  heading,
  isButton,
  size,
}: any) => {
  return (
    <div className="pt-5 pb-10">
      <h1 className="text-center font-[800] text-3xl md:text-5xl pb-5 md:pb-16 md:pt-8">
        {heading}
      </h1>
      <GridStyle>
        {children?.map((item: any) => (
          <WorkComponent
            heading={item.heading}
            body={item.body}
            img={item.img}
            isButton={isButton}
            size={size}
            center={item.center}
            link={item.link}
          />
        ))}
      </GridStyle>
    </div>
  );
};


export const WorkComponent = ({
  img,
  heading,
  body,
  isButton,
  size,
  center,
  link,
}: any) => {
  return (
    <div className="flex flex-col md:items-start items-center md:w-full">
      <img
        src={img}
        alt={heading}
        className={`w-[${!size ? 200 : size.width}px] h-[${!size ? 200 : size.height}px] object-contain `}
      />
      <div className="text-center md:text-left">
        <h3 className="text-xl md:text-2xl font-bold py-2 md:py-3">
          {heading}
        </h3>
        <p className="font-medium leading-7 md:text-lg">{body}</p>
      </div>
      <br />
      <a href={link} target="_blank">
        {isButton && 
        <button className="rounded-[59.65px] px-[46.5px] py-[11px] outline-none cursor-pointer text-[16px]
        transition-all bg-[#FFFFFF] !text-[#008300] border border-[#DCEBE3] hover:bg-[#DCEBE3] font-[700]">
            Join Now
          </button> 
        // <Button width={true} white={false}  content='Join Us'/>
        }
      </a>
    </div>
  );
};


export const ParentCardContainer = ({ title, isButton }: any) => {
  return (
    <>
    <div className=" md:px-[4rem] lg:px-[6rem] xl:px-[8rem] ">
        {/* <CardContainer> */}
          <div className='relative flex justify-center items-center flex-col w-full rounded-md space-y-4 bg-primary h-[22vh] md:h-[30vh]  xl:h-[45vh]'>
            <Bg />
            <Star />
            <Star2 />
            <Title desc={title}/>
            <Link to="signup" smooth={true}>
              {isButton && 
              <div>
              <button className="rounded-[59.65px] px-[46.5px] py-[11px] outline-none cursor-pointer text-[16px]
              transition-all bg-[#FFFFFF] !text-[#008300] hover:bg-[#DCEBE3] font-[700]">
                  Join Now
                </button>                
              </div>

              // <Button content="Join now" width={true} primary={false} />
              }
            </Link>            
          </div>

        {/* </CardContainer> */}
      </div>
    </>
  );
};

