
import { PerksBenefit } from "../../../utils/data/data";
import PerkCard from "./PerkCard";
// for first-child
// md:col-span-1 md:row-span-2 md:self-start

const Perks = () => {
  return (
    <div>
      <h2 className="font-bold text-body text-center text-2xl  lg:text-3xl">Perks and Benefits</h2>
      <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
        {PerksBenefit?.map((perks:any, index:any) => {
          return <PerkCard {...perks} key={index} />;
        })}
      </div>
    </div>
  );
};

export default Perks;
