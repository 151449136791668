import React, { useState } from "react";
import { ParentWorkComponent } from "../about/component";
import { HowItWork } from "../../utils/data/data";

export const HowItWorks = () => {
  const [isButton, setIsButton] = useState(false);
  const size = { width: 200, height: 200 };
  return (
    <>
      <ParentWorkComponent
        children={HowItWork}
        heading="How does it work?"
        isButton={isButton}
        size={size}
      />
    </>
  );
};
