import { KPIFeaturesInfo } from "../../utils/data/data";
import NeoCard from "../common/NeoCard";
import { FeatureSection } from "../common/styles";
import KPICard from "./KPICard";


const Benefit = ({data}:any) => {
  const articles = data;
  return (
    <FeatureSection>
      {articles?.map((article:any, i:any) => {
        return <KPICard {...article} key={i} />;
      })}
    </FeatureSection>
  );
};

export default Benefit;
