
import { Flex, ImageContains, Paragraph } from "./styles";
import { EarnWasteModel } from "../../utils/data/data";



const FlexContainer = ({
  text = "",
  reverse,
  imgAlt = "",
  imgSrc = "",
  top,
}: EarnWasteModel) => {
  return (
    <>
      <Flex reverse={reverse ? true : false} top={true}>
        <div className=" w-full lg:w-[65%]">
          <Paragraph reverse={reverse ? true : false} text={text}/>
        </div>

        {/* <ImageContainer> */}
        {/* <ImageImage
            src={imgSrc}
            alt={imgAlt}
            width={600}
            height={300}
            layout="fixed"
          ></ImageImage> */}
        {/* </ImageContainer> */}
          <div className="w-full lg:w-[30%] flex justify-center items-center">
          <ImageContains
          top={true}>
            <img
              src={imgSrc}
              alt={imgAlt}
              width={430}
              height={450}
              className="object-contain"
            />
          </ImageContains>
          </div>

      </Flex>
    </>
  );
};

export default FlexContainer;
