import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

type Props = {
  elements: {
    tabTitle: string;
    tabBody: JSX.Element;
    path: string;
    tabIcon?: string;
  }[];
  basePath: string;
};

export default function CustomTab(props: Props) {
  const { elements, basePath } = props;
  const router = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string | string[]>(elements[0]?.path || "");

  useEffect(() => {
    // Set the initial active tab based on the query parameter "source"
    const { search } = location;
    const params = new URLSearchParams(search);
    const source = params.get("source");
    setActiveTab(source || elements[0]?.path || "");
  }, [location, elements]);

  const handleTabClick = (path: string | string[]) => {
    setActiveTab(path);
    router(`${basePath}/?source=${path}`);
  };

  const getTabClasses = (element: typeof elements[0]) => {
    return `px-4 pt-2 text-lg font-medium ${
      activeTab === element.path ? "text-primary" : "text-gray"
    }`;
  };

  return (
    <>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {elements.map((element) => (
            <button
              key={element.path}
              onClick={() => handleTabClick(element.path)}
              className={getTabClasses(element)}
            >
              <span>{element.tabTitle}</span>
              <div
                className={`h-[2px] w-full ${
                  activeTab === element.path ? "bg-primary" : ""
                }`}
              ></div>
            </button>
          ))}
        </div>
      </div>
      <hr className="bg-gray opacity-10" />
      <div className="flex-1 p-6">
        {elements.find((e) => e.path === activeTab)?.tabBody}
      </div>
    </>
  );
}
