declare global {
    interface Window {
      fbq?: (action: string, name: string, options?: any) => void;
    }
  }
  
  export const FB_PIXEL_ID = process.env.FB_PIXEL_ID;
  
  export const pageview = () => {
    if (window.fbq) {
      window.fbq('track', 'PageView');
    }
  }
  
  export const event = (name: string, options: any = {}) => {
    if (window.fbq) {
      window.fbq('track', name, options);
    }
  }
