import React from 'react'
import { whatwedo } from '../../utils/data/data'
import { Column, Container, Row } from '../common/AltCard'
import GreenBackDrop from '../common/Green'

const WhatWeDo = () => {
  return (
    <GreenBackDrop bgCol='transparent'>
      <Container>
      <h2 className='text-primary text-5xl lg:text-6xl font-extrabold mb-4 text-center'>What We Do</h2>
        
        <Row>
          <Column>
            <div>
              <p className=' leading-[1.75rem] lg:leading-[2.5rem] text-[#21312a]'>
                We connect ecosystem stakeholders by ensuring ecosystem
                inclusion that connects waste generators with waste collectors
                across the value chain. Our innovation has created a waste-on-
                demand solution and has unified all categories of waste entities
                under a single platform. It provides an end to end data
                aggregation and insight to stakeholders. Furthermore, we provide
                a learning platform for waste generators to know how to handle
                waste and ensure a cleaner environment.
              </p>
            </div>
          </Column>
          <Column>
            <div className='grid grid-cols-2 grid-rows-2 gap-4 lg:py-12 lg:pl-20 mt-10 lg:mt-0'>
              {whatwedo.map((el, i) => {
                return (
                  <div 
                    key={i}
                    className="hover:-translate-y-1.5 hover:ease-in-out duration-300 bg-white flex flex-col justify-between items-center px-2 lg:px-8 py-8 space-y-4 shadow-sm rounded-lg"
                  >
                    <img
                      src={el.imgUrl}
                      alt="doings"
                      width={57.04}
                      height={57.04}
                    />
                    <p className="text-xs md:text-sm text-center text-[#21312a]">{el.title}</p>
                  </div>
                );
              })}
            </div >
          </Column>
        </Row>
      </Container>
    </GreenBackDrop>

  )
}

export default WhatWeDo
