import React from "react";
import { partnershipfaqs } from "../../utils/data/data";
import Accordion from "../common/Accordion";
import { ParentCardContainer } from "../about/component";

export const LongCard = ({ title }: any) => {
  return (
    <>
      <ParentCardContainer title="Our most popular FAQs" />
      <br />
      <div className="pt-6">
        <Accordion data={partnershipfaqs} />        
      </div>

    </>
  );
};
