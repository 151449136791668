import React from 'react'
import { Column, Container, Row } from '../common/AltCard';

interface MVCardProps{
    img: string;
    title:string;
    desc:string
}


const MVCard = ({img, title, desc}: MVCardProps) =>{
    return(
        <div className='flex flex-col items-start  lg:px-10 gap-4'>
            <img src={img} className='w-[150px] md:w-[200px] h-[150px] md:h-[200px]' alt="mission and vision icons" />
            <h3 className='font-extrabold text-6xl text-primary'>{title}</h3>
            <div className='md:w-[80%]'>
                <p>{desc}</p>
            </div>
        </div>
    )
}

const MissionVision = () => {
  return (

        <div className='min-h-full w-full bg-goal-pattern bg-cover bg-no-repeat py-[4rem] lg:py-[10.2rem]'>
            <Container>
                <Row>
                    <Column>

                            <MVCard img="/img/mission.png" title='Our Mission.' desc='Our mission is to achieve waste scarcity with digital tools and
                                                build a sustainable, robust circular economy, one community at a
                                                time.'/>
 
                    </Column>
                    <Column>
                        <MVCard img='/img/vision.png' title='Our Vision.' desc='Our vision is to become a leading global waste technology service provider that meets global waste challenges.'/>

                    </Column>
                </Row>
            </Container>
        
        </div>        


  )
}

export default MissionVision
