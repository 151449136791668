import React from 'react'
import { Heading, OverallContainer } from '../common/styles';
import { Container } from '../common/AltCard';
import FlexContainer from '../common/FlexContainer';
import { earnarticles } from '../../utils/data/data';


const ProductHero = ({title, data} : any) => {
  return (
    <div className='pt-[80px]'>
      <OverallContainer>
        <Container>
          <div className='flex flex-col justify-center items-center w-full'>
            <Heading text={title}/>
            <div>
              {data?.map((article:any, i:any) => {
                return <FlexContainer {...article} key={i} />;
              })}              
            </div>

          </div>

        </Container>
      </OverallContainer>
    </div>
  )
}

export default ProductHero
