import React, { Children, ReactNode } from "react";
import { Link } from "react-router-dom";

interface AltCardProps{
    id?: number;
    headline: string;
    subtext: string;
    link: string;
    imgAlt: string;
    imgSrc: any;
    info: { imgSrc: any; text: string }[];
    dates: { imgSrc: any; text: string }[];
    bg?: boolean;
    color?: boolean;
    decoration?: boolean;
    inPage?: boolean;
  }

  interface H2props{
    text:string
  }

  interface DivProps {
    children: ReactNode;
  }

  export const CardContainer:React.FC<DivProps> = ({children})=>(
    <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3 gap-5  pb-40 pt-20">
        {children}
    </div>
)
  export const Container:React.FC<DivProps> = ({children})=>(
    <div className="z-1 w-full max-w-[1400px] mx-auto px-[16px] md:px-[25px] lg:px-[50px] ">
        {children}
    </div>
)
  export const Row:React.FC<DivProps> = ({children})=>(
    <div className="flex mx-[-15px] mb-[-15px] flex-wrap items-center content-stretch flex-col lg:flex-row ">
        {children}
    </div>
)
  export const Column:React.FC<DivProps> = ({children})=>(
    <div className="mb-[15px] px-[15px] flex-1 max-w-[100%] lg:max-w-[50%] basis-[50%] lg:basis-[50%]">
        {children}
    </div>
)
  export const GridStyle:React.FC<DivProps> = ({children})=>(
    <div className="grid  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
        {children}
    </div>
)
export const Div:React.FC<DivProps> = ({children})=>(
  <div className="flex justify-between text-xs font-medium">
      {children}
  </div>
)
export const Flex:React.FC<DivProps> = ({children})=>(
  <div className="flex justify-between text-lg">
      {children}
  </div>
)
const AltCard = ({
    id,
    imgAlt,
    imgSrc,
    headline,
    subtext,
    link,
    info,
    dates,
    bg,
    color,
    decoration,
    inPage,
  }: AltCardProps ) => {
    // const router = useRouter();

    const H2 = ({text}:H2props)=>(
        <h2 style={{color: `${color ? "#fff" : "#005700"}`, textDecoration:`${decoration ? "none" : "underline"}`}} className="text-[14px] md:text-base mt-5 pb-3 leading-[35px] font-bold transition-all duration-500 ease-in-out tracking-normal cursor-pointer">
            {text}
        </h2>
    )
    const H3 = ({text}:H2props)=>(
        <h3 className="text-xs  tracking-wider pt-2 pb-6 leading-5 font-medium">
            {text}
        </h3>
    )


    return (
      <section style={{background: `${bg ? " #005700;" : "white"}`, boxShadow: '0px 9.34521px 27.4405px rgba(215, 228, 249, 0.7)'}} className="rounded-sm min-h-[200px] w-[100%]  pb-5 bg-white" >
        <div style={{color: `${color ? " white;" : "black"}`}} className="pl-5 pr-5">
          <div style={{ marginTop: "20px" }}>
            <img
              src={imgSrc}
              width={500}
              height={300}
              className="mt-10 object-cover"
            />
          </div>
          {/* <Link to={link} target="_blank">
            <H2 col={color ? true : false} decoration={decoration ? true : false}>
              {headline}
            </H2>
          </Link> */}
  
          {inPage ? (
            <Link to={`press/${id}`}>
              <H2 text={headline}/>
            </Link>
          ) : (
            <Link to={link} target="_blank">
              <H2 text={headline}/>
            </Link>
          )}
  
          <H3 text={`${subtext.substring(0, 150)}....`}/>
  
          <Flex>
            <Div>
              {info?.map(({ text, imgSrc }, index) => (
                <div className="flex gap-1">
                  <img src={imgSrc} alt="Pakam" width={15} height={15}/>
                  <small>{text}</small>
                </div>
              ))}
            </Div>
            <Div>
              {dates?.map(({ imgSrc, text }, index) => (
                <div className="flex gap-1 items-center">
                  <img src={imgSrc} alt="Pakam" width={10} height={10}/>
                  <small>{text}</small>
                </div>
              ))}
            </Div>
          </Flex>
        </div>
      </section>
    );
  };
  
  export default AltCard;
  