import React from 'react'

interface BannerProps{
    para: string;
    title: string;
}

const Banner = ({para, title}: BannerProps) => {
  return (
    <div style={{backgroundColor:'rgba(0, 87, 0, 1)', color:'rgba(255, 255, 255, 1)'}} className='w-full h-[280px] lg:h-[350px] flex justify-center items-center '>
      <div>
        <p className='font-[400] font-[Raleway] text-center' >{para}</p>
        <h2 className='font-[800] font-[Raleway] text-[48px] lg:text-[72px] text-center'>{title}</h2>        
      </div>

    </div>
  )
}

export default Banner
