import React, { ReactNode } from 'react'

interface GreenProp{
    children : ReactNode;
    bgCol: string
}

const GreenBackDrop : React.FC<GreenProp> = ({children, bgCol}) => {
  return (
    <div className={`bg-${bgCol} py-[4rem] lg:py-[5.2rem] text-white `}>
      {children}
    </div>
  )
}

export default GreenBackDrop
