import React, { useState } from 'react'
import { faqRequest } from '../../hooks/ApiRequest';
import { toast } from 'react-toastify';
import Input from '../common/Input';
import Button from '../common/Button';

const FaqForm = () => {
    const [primary, setPrimary] = useState(true);
    const [white, setWhite] = useState(true);
  
    const defaultFormData: {} = {
      email: "",
      message: "",
    };
  
    const [values, setValues] = useState(defaultFormData);
  
    const [email, setEmail] = useState<string>("");
    const [message, setMessage] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [alert, setAlert] = useState(true);
  
    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      // setValues({ ...values, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | any) => {
      e.preventDefault();
      const form = new FormData(e.target);
      const data = Object.fromEntries(form.entries());
  
      try {
        setIsLoading(true);
        const sendData = await faqRequest(data);
        if (sendData?.data?.statusCode >= 400) {
          setIsLoading(false);
          // setErrorMessage(sendData.data.error.response?.data.error);
          toast(sendData.data.error.response?.data.error, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setError(true);
        } else {
          setIsLoading(false);
          // setSuccess(true);
          toast("Question successfully sent!!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setEmail("");
          setMessage("");
        }
      } catch (error) {
        console.log("error", error);
      }
    };
  return (
    <div className=' flex flex-col items-center py-[5rem]' id="form">
    <h3 className='font-bold text-3xl lg:text-5xl mb-10 text-center text-[#21312a]'>Still have questions? Shoot.</h3>
    {isLoading && (
      <div className="text-base pt-5 text-primary">
        Form Submission in Progress...
      </div>
    )}
    <form className='sm:w-[90%] lg:w-[55%] xl:w-[43%] flex flex-col justify-center ' onSubmit={handleSubmit}>
      <div className='flex flex-col mb-[24px] w-full'>
        <label className='flex text-xs md:text-sm md:pb-2 font-medium text-left'> Email</label>
        <Input
          placeholder="Email"
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required={true}
        />
      </div>
      <div>
        <label className='flex text-xs md:text-sm md:pb-2 font-medium text-left'> Message</label>
        <textarea className='rounded-[5px] bg-[#fff] py-[0.3rem] px-[1em] text-[16px] text-[#000] outline-none shadow-none border border-[#c2c2c2] transition-all duration-[0.3s] ease-out relative font-[500] w-full focus:border-2'
            cols={30}
            rows={5} 
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        </div>


      <div className='mt-4 flex mx-auto'>
      <button className="rounded-[59.65px] px-[46.5px] py-[11px] outline-none cursor-pointer text-[16px]
        transition-all bg-[#008300] font-[700] text-[#fff] hover:bg-[#145C53]">
            Send Message
          </button>
        {/* <Button content='Send Message' primary={primary} white={white}/> */}
      </div>
    </form>
  </div>
  )
}

export default FaqForm