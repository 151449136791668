import React from 'react'
import { Container } from '../common/AltCard'
import Lottie from 'lottie-react'
import EmptyState from "../../utils/empty-state.json"

const NoOpening = () => {
  return (
    <div>
        <div className='flex items-center justify-center py-28 bg-white'>
          <Container>
            <div className='flex flex-col items-center justify-center space-y-6'>
              <Lottie
                animationData={EmptyState}
                loop={true}
                className="w-2/4 h-3/5 lg:w-1/4 lg:h-1/5"
              />
              <h6 className="text-center text-lg lg:text-2xl font-extrabold text-4xl">
                No job openings at this time
              </h6>
              <p className="text-center text-lg md:text-xl lg:text-3xl font-thin text-xl">
                Please check back at another time.
              </p>
            </div>
          </Container>
        </div >
    </div>
  )
}

export default NoOpening