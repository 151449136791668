import React from 'react'
import BannerSection from '../common/BannerSection'
import { awards } from '../../utils/data/data'

const Awards = () => {
  return (
    <div className='mb-4'>
    <BannerSection
      title="Awards and recognition"
      imgUrl={awards}
      classname="object-contain !min-h-[100px] lg:!min-h-full"
      width={'240px'}
      height={'100px'}
    />
  </div>
  )
}

export default Awards