export const data = [
    {
        title: 'Company',
        dropdown: [
            {
                title:'About us',
                icon:'/svg/abtIcon.svg',
                link:'/about'
            },
            {
                title:'Join our Team',
                icon:'/svg/JoinIcon.svg',
                link:'/careers'
            },
            {
                title:'Reviews',
                icon:'/svg/RevIcon.svg',
                link:'/reviews'
            }
        ]
    },
    {
        title: 'Products',
        dropdown: [
            {
                title:'Earn as You Waste',
                icon:'/svg/EarnIcon.svg',
                link:'/products/earn'
            },
            {
                title:'Pay as You Waste',
                icon:'/svg/PayIcon.svg',
                link:'/products/pay'
            },
            {
                title:'Smart Enforcement',
                icon:'/svg/SmartIcon.svg',
                link:'/products/smart'
            }
        ]
    },
    {
        title: 'Resources',
        dropdown: [
            {
                title:'Blog',
                icon:'/svg/blogIcon.svg',
                link:'/blog'
            },
            {
                title:'News',
                icon:'/svg/NewsIcon.svg',
                link:'/news'
            },
            {
                title:'Publication & Podcast',
                icon:'/svg/PubIcon.svg',
                link:'/publications'
            }
        ]
    },
    {
        title: 'Help',
        dropdown: [
            {
                title:'Support',
                icon:'/svg/SupIcon.svg',
                link:'/support'
            },
            {
                title:'Contact',
                icon:'/svg/ContIcon.svg',
                link:'/contact-us'
            },
            {
                title:'FAQs',
                icon:'/svg/FAQIcon.svg',
                link:'/faqs'
            }
        ]
    }
]