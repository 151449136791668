import React from 'react'
import { BackgroundContainer } from '../common/styles'
import { Container } from '../common/AltCard'
import { earncontentarticles } from '../../utils/data/data'
import NeoCard from '../common/NeoCard'


const ProductContent = ({data}:any) => {
    const articles = data;
  return (
    <BackgroundContainer>
        <Container>
            {articles.map((article:any, index:any) => {
            return <NeoCard {...article} key={index} />;
            })}
        </Container>
    </BackgroundContainer>
  )
}

export default ProductContent