import { ReviewData } from "../../utils/data/data";
import { Content, Name, Role, Wrapper } from "./styles";



const TestimonialCard = ({ content, name, role }: ReviewData) => {
  return (
    <Wrapper>
      <div className="relative">
        <div className="absolute top-[20px] right-[-15px]">
          <img src={'/img/Quote.png'} width={150} height={100}/>
        </div>
        <Name text={name || "Michael Ogunleye"}/>
        <Role text={role || "household owners"}/>

        {/* <RiDoubleQuotesR
          size={170}
          color="rgba(0, 87, 0, 0.24)"
          style={{
            position: "absolute",
            top: "-30px",
            right: "-30px",
            zIndex: 2,
          }}
        /> */}

        <Content text={content ||
            " Once you schedule they will always find you to Pickup. Very fast, efficient and reliable. I recommend foe every household."}/>
      </div>
    </Wrapper>
  );
};

export default TestimonialCard;
