import React, { useEffect, useRef } from "react";
import VanillaTilt from "vanilla-tilt";
import { Container } from "./AltCard";


interface divProps {
    children: React.ReactNode
    imgStart?:string
}
const ArticleHeroSection:React.FC<divProps> = ({children}) =>{
    return(
    <div className='py-[3rem] lg:py-[5rem] bg-secondary'>
        {children}
    </div>        
    )
}
const ArticleHeroCol:React.FC<divProps> = ({children}) =>{
    return(
    <div className='max-w-full basis-[100%] lg:basis-[50%] lg:max-w-[50%] px-[15px] mb-[15px] flex-1  '>
        {children}
    </div>        
    )
}
const ArticleHeroImgWrapper:React.FC<divProps> = ({children}) =>{
    return(
    <div className='max-w-[555px] '>
        {children}
    </div>        
    )
}


const ArticleHeroRow:React.FC<divProps>  =({children, imgStart} )=>{
    return(
        <div className={`flex my-[15px]  mt-[3rem] ml-[-15px] flex-wrap content-stretch items-center ${imgStart === "start"? 'flex-row-reverse' : 'row'}`}>
            {children}
        </div>
    )
}





type props = {
  imgStart?: any;
  header?: any;
  id?: any;
  title?: any;
  text?: string;
  img: string;
};

function ArticleSection({ title, imgStart, header, id, text, img }: props) {
  const tilt = useRef(null);
  useEffect(() => {
    return VanillaTilt.init(tilt.current!, {
      reverse: true,
    });
  }, []);
  return (
    <>
        <section id={id} className="z-[-1]">
            <ArticleHeroSection >
                <Container>
                {title ? <h1 className='text-primary text-5xl lg:text-6xl font-extrabold mb-4'>{title}</h1> : null}
                <ArticleHeroRow imgStart={imgStart} >
                    <ArticleHeroCol>
                    {header ? <h1 className='text-primary text-5xl lg:text-6xl font-extrabold mb-4'>{header}</h1> : null}
                    <p className='md:text-lg text-base font-normal'>{text}</p>
                    </ArticleHeroCol>
                    <ArticleHeroCol>
                        <div ref={tilt} className="z-[-1]">
                        <ArticleHeroImgWrapper >
                            <img
                            src={img}
                            height={542}
                            width={573}
                            className="object-contain flex self-end"
                            />
                        </ArticleHeroImgWrapper>
                        </div>

                    </ArticleHeroCol>
                </ArticleHeroRow>
                </Container>
            </ArticleHeroSection>
        </section>

    </>
  );
}

export default ArticleSection;
