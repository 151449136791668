import { members } from "../../utils/data/data";
import BannerSlide from "../common/BannerSlide";
import Marquee from 'react-fast-marquee';

const Members = () => {
  return (
    <div className="bg-white lg:mt-[3rem]">
          <div className=' w-full py-8'>
        <h1 className='font-[600] text-center text-[14px] md:text-2xl text-[#35393F] font-int '>WE ARE MEMBERS OF.</h1>

        <div className="mt-8 hidden md:block">
          <div
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-2"
            className='flex justify-center items-center flex-wrap'
          >
            {members.map((el, i) => (
              <img
                key={i}
                src={el}
                className={`w-[200px] h-[64px] object-contain`}
              />
            ))}
          </div>
      </div>

      <div className=' w-full py-4 md:hidden'>
        <Marquee
          gradient={true}
        //   gradientColor={[248, 251, 251]}
          direction= {'left'}
          // speed={speed}
        >
        <div className="mt-8">
          <div
            data-scroll
            data-scroll-direction="horizontal"
            data-scroll-speed="-2"
            className='flex justify-center items-center flex-wrap'
          >
            {members.map((el, i) => (
              <img
                key={i}
                src={el}
                className={`w-[200px] h-[64px] object-contain`}
              />
            ))}
          </div>
      </div>
        </Marquee>
      
    </div>
      
    </div>
    
    </div>

  );
};

export default Members;
