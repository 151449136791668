
import { PublicationData } from "../../utils/data/data";
import AltCard, { CardContainer, Container } from "../common/AltCard";

const Publications = () => {
  return (
    <>
      <Container>
        <CardContainer>
          {PublicationData?.map((article, index) => (
            <AltCard {...article} key={index} />
          ))}
        </CardContainer>
      </Container>
    </>
  );
};

export default Publications;
