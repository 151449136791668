import React, { useState, useEffect } from "react";
import {
  TBody,
  TRow,
} from "./style";
import { Openings } from "./data";
import Perks from "./Perks/Perks";
import Contact from "./contact/Contact";
import OpeningsModal from "./Modal/OpeningModal";
// @ts-ignore
import Paginator from "react-hooks-paginator";
import Button from "../common/Button";
import { Container } from "../common/AltCard";
import Banner from "../common/Banner";
import {CareerPageBanner} from "../common/PageBanner";
import TopModal from "./Modal/OpeningModal";

const CareerPage = () => {
  const [currentData, setCurrentData] = useState<Array<any>>([]);
  const [offset, setOffset] = useState(0);
  const pageLimit = 3;
  const [popupContent, setPopupContent] = useState<Array<any>>([]);
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setCurrentData(Openings.slice(offset, offset + pageLimit));
  }, [offset, pageLimit]);

  const changeContent = (openings: any) => {
    setShowModal(true);
    setPopupContent([openings]);
  };

  const handleClose = () =>{
    setShowModal(false)
  }

  return (
    <div className="bg-secondary">
       <CareerPageBanner para='With Pakam, you can build a global career, without giving up on your locality. Our amazing team are diverse in tribes and ideas driven.' text='Be part of our team'>
          <button className="px-[52px] h-[53px] py-[7px] bg-white text-primary font-[600] rounded-[10px]">
            Submit Resume
          </button>
       </CareerPageBanner>
    <Container>

      <div className="w-full flex flex-col items-center justify-center py-12 " id="currentopening">
        <table className="w-[100%] flex flex-col flex-nowrap  overflow-hidden sm:shadow-sm my-5 sm:!inline-table">
          <thead style={{borderBottom: '2px solid #EFF0F7'}} className="text-lg border md:border-0 md:border-b-2 ">
            <tr className="flex flex-col flex-nowrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0 font-extrabold text-body">
              <th className="`p-3 text-left">Job Title</th>
              <th className="`p-3 text-left">Duration</th>
              <th className="`p-3 text-left">Work-type</th>
              <th className="`p-3 text-left">Details</th>
            </tr>
  
          </thead >

          <tbody className="flex-1 sm:flex-none">
            {currentData.map((el) => {
              return (
                <tr className="flex flex-col flex-nowrap sm:table-row mb-2 sm:mb-0">
                  <td className=" p-2 sm:p-3 border-b-[1.5px] border-b-[#EFF0F7]">{el.JobTitle}</td>
                  <td className=" p-2 sm:p-3 border-b-[1.5px] border-b-[#EFF0F7]">{el.Duration}</td>
                  <td className=" p-2 sm:p-3 border-b-[1.5px] border-b-[#EFF0F7]">{el.Worktype}</td>

                  <td className=" p-2 sm:p-3 border-b-[1.5px] border-b-[#EFF0F7]">
                    <div onClick={() => changeContent(el)}>
                      <button style={{border:'1px solid #464f54'}} className="rounded-[10px] w-[100px] h-[40px] text-sm font-semibold flex transition-all duration-500 items-center justify-center border text-[#008300] border-[#008300] bg-white hover:bg-[#008300] hover:text-[#fff]">
                        See Details
                      </button>
                      {/* <Button
                        content="See Details"
                        white={false}
                      
                      /> */}
                    </div>
                    
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Paginator
          totalRecords={Openings.length}
          pageLimit={3}
          pageNeighbours={1}
          setOffset={setOffset}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>

      {showModal && (
        <TopModal
          open={showModal}
          onClose={handleClose}
          popupContent={popupContent}
        />
      )}




      <Perks />
      <Contact />
    </Container>

    </div>

  );
};

export default CareerPage;
