import { Container } from '@mui/material'
import React from 'react'

const How = () => {
  return (
    <Container>
      <div className='flex flex-col-reverse md:flex-row my-12 z-[-1]'>
          <div data-aos="fade-right" data-aos-anchor-placement="top-center" data-aos-easing="ease-out"
     data-aos-duration="1000" className='w-full md:w-1/2'>
              <div className='pt-[2rem] lg:pt-0'>
                  <h1 className='text-[#35393F] font-Rob font-[800] text-[28px] md:text-[48px] leading-[45px] text-center md:text-left'>How It Works</h1>
                  <p className='font-int font-[500] text-[16px] text-[#7A828F] my-4 text-center md:text-left'>Get started in these simple steps:</p>
              </div>

              <div className='space-y-3 flex flex-col justify-center items-center md:justify-normal md:items-start relative mt-6'>
                <img className=' lg:block hidden absolute w-[110px] h-[100px] top-[3rem] z-[-1] left-4' src="/img/angle1.png" alt="angle" />
                <img className=' lg:block hidden absolute  w-[110px] h-[100px] top-[15rem] z-[-1] left-[8rem]' src="/img/angle2.png" alt="angle" />
                <img className=' lg:hidden absolute left-[24px] md:left-[16px] top-[5%] z-[-1] h-[350px] ' src="/img/How-line.png" alt="angle" />


                <div className='flex gap-1 lg:gap-6 '>
                        <div className='w-[40px] h-[40px] flex justify-center items-center bg-[#005A00] rounded-full'>
                          <p className='text-white text-[24px] font-[400]'>1</p>
                        </div>
                        <div className={`border border-[#DFE2E7] shadow-sm p-2 rounded-lg w-[300px] h-[100px] flex flex-col gap-4 hover:shadow-lg transition-all ease-in-out duration-500 hover:translate-x-3`}>
                          <p className='text-[#444951] text-[14px] font-int font-[600]'>Create an account</p>
                          <p className='text-[#7A828F] text-[12px] font-int font-[400]'>Get the Pakam Household App from <br />Google Play Store or IOS App Store</p>
                        </div>                        
                  </div>
                <div className='flex gap-1 lg:gap-6 lg:pl-[6.5rem]'>
                        <div className='w-[40px] h-[40px] flex justify-center items-center bg-[#005A00] rounded-full'>
                          <p className='text-white text-[24px] font-[400]'>2</p>
                        </div>
                        <div className={`border border-[#DFE2E7] shadow-sm p-2 rounded-lg w-[300px] h-[100px] flex flex-col gap-4 hover:shadow-lg transition-all ease-in-out duration-500 hover:translate-x-3`}>
                          <p className='text-[#444951] text-[14px] font-int font-[600]'>Verify and Sign-In</p>
                          <p className='text-[#7A828F] text-[12px] font-int font-[400]'>Verify your phone number, fill in necessary details, and sign in to the app.</p>
                        </div>                        
                  </div>
                <div className='flex gap-1 lg:gap-6 lg:pl-[13.5rem]'>
                        <div className='w-[40px] h-[40px] flex justify-center items-center bg-[#005A00] rounded-full'>
                          <p className='text-white text-[24px] font-[400]'>3</p>
                        </div>
                        <div className={`border border-[#DFE2E7] shadow-sm p-2 rounded-lg w-[300px] h-[100px] flex flex-col gap-4 hover:shadow-lg transition-all ease-in-out duration-500 hover:translate-x-3`}>
                          <p className='text-[#444951] text-[14px] font-int font-[600]'>Submit Request</p>
                          <p className='text-[#7A828F] text-[12px] font-int font-[400]'>Click on your preferred recycling option, fill in the required details, and submit your request</p>
                        </div>                        
                  </div>
                <div className='flex gap-1 lg:gap-6 lg:pl-[7rem]'>
                        <div className='w-[40px] h-[40px] flex justify-center items-center bg-[#005A00] rounded-full'>
                          <p className='text-white text-[24px] font-[400]'>4</p>
                        </div>
                        <div className={`border border-[#DFE2E7] shadow-sm p-2 rounded-lg w-[300px] h-[100px] flex flex-col gap-4 hover:shadow-lg transition-all ease-in-out duration-500 hover:translate-x-3`}>
                          <p className='text-[#444951] text-[14px] font-int font-[600]'>Instant Wallet Credit</p>
                          <p className='text-[#7A828F] text-[12px] font-int font-[400]'>Once a collector accepts and completes the request, your wallet will be instantly credited.</p>
                        </div>                        
                  </div>
              </div>

          </div>
          <div data-aos="fade-left" data-aos-anchor-placement="top-center" data-aos-easing="ease-out"
     data-aos-duration="1000" className='w-full md:w-1/2 flex justify-center items-center relative z-[-1]'>
            <img src="/svg/phonecircle.svg" className='absolute w-[400px] z-[-1]' alt="circle" />
            <img  className='w-[300px]' src="/img/Pakam-video.gif" alt="pakam-video" />
              {/* <video className='w-[300px]' src="/img/Pakam_video.mp4" loop autoPlay></video> */}
          </div>

      </div>      
    </Container>

  )
}

export default How


const howData =[
  {
    id: 1,
    title:'Create an account',
    desc:'Get the Pakam Household App from Google Play Store or IOS App Store',
    pl:'0'
  },
  {
    id: 2,
    title:'Verify and Sign-In',
    desc:'Verify your phone number, fill in necessary details, and sign in to the app.',
    pl:'3rem'
  },
  {
    id: 3,
    title:'Submit Request',
    desc:'Click on your preferred recycling option, fill in the required details, and submit your request',
    pl:'5rem'
  },
  {
    id: 4,
    title:'Instant Wallet Credit',
    desc:'Once a collector accepts and completes the request, your wallet will be instantly credited.',
    pl:'2rem'
  },
]