import React, { useState } from "react";
import { ParentCardContainer } from "../about/component";

export const PartnerCard = () => {
  const [isButton, setIsButton] = useState(true);
  return (
    <div className="py-10">
      <ParentCardContainer title="Become a Partner" isButton={isButton} />
    </div>
  );
};
