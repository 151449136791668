import { FC } from "react";
import { Container } from "./AltCard";


type props = {
  title: string;
  classname: string;
  height: string;
  width: string;
  imgUrl: Array<string>;
};

const BannerSection: FC<props> = ({
  title,
  imgUrl,
  classname,
  width,
  height,
}) => {
  return (
    <div className="bg-white py-6 lg:py-6">
      <Container>
        <h4 className="text-blak font-extrabold text-center text-2xl text-primary">{title}</h4>
        <div className="mt-8 flex items-center justify-center flex-wrap">
          {imgUrl.map((el, i) => (
            <div key={i}>
              <img
                src={el}
                width={width}
                height={height}
                className={`w-[${width}] h-[${height}] ${classname}`}
              />
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default BannerSection;
