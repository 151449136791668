import React from 'react'
import {Link} from 'react-router-dom'

interface BtnProps{
    title: string;
    link: string;
}

const LinkBtn = ({title, link}: BtnProps) => {
  return (
    <Link to={link} className='bg-[#008300] hover:bg-[#145C53] rounded-3xl text-[16px] font-[700] text-[#FFFFFF]  w-full px-[44px] py-[12px]  '>
      {title}
    </Link>
  )
}

export default LinkBtn
